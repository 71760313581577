import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { EventDetails, Media } from '../../shared/models/eventDetails';
import { Subject, catchError, takeUntil, tap } from 'rxjs';
import { VIDEO, FILE_DOWNLOAD_ERROR } from '../../shared/constants/camera-profile-constant';
import { MessageService, SlbSeverity } from '@slb-dls/angular-material/notification';
import { TimeZoneService } from '../../shared/services/time-zone.service';
import { DashboardService } from '../../shared/services/dashboard.service';
import { CameraProfileService } from '../../shared/services/camera-profile.service';

@Component({
  selector: 'app-alerts-grid',
  templateUrl: './alerts-grid.component.html',
  styleUrls: ['./alerts-grid.component.scss'],
})
export class AlertsGridComponent implements OnChanges, AfterViewInit, OnDestroy {
  @Input() gridData: EventDetails[];
  @Input() gatewayId: string;
  @Input() controllerId: string;
  @Input() totalNoRecords: number;
  @Input() paginatedData: PageEvent;
  @Output() pageData: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
  @Output() selectedData: EventEmitter<EventDetails[]> = new EventEmitter<EventDetails[]>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public popOverCameraDetails: EventDetails | null;
  public currentZone = '';
  public hasNext = false;
  public hasPrevious = false;
  public selectedPopOverIndex = -1;
  public isOpen = false;
  public sessionToken: string;
  private destroyed = new Subject();

  constructor(
    private timeZoneService: TimeZoneService,
    private messageService: MessageService,
    private dashboardService: DashboardService,
    private cameraProfileservice: CameraProfileService
  ) {
    this.currentZone = this.timeZoneService.getTimeZone();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.sessionToken = sessionStorage.getItem('access_token') ?? '';
    if (changes && changes['gridData'] && changes['gridData'].currentValue !== '') {
      this.gridData?.map((data: EventDetails) => {
        if (data) {
          data.controllerId = this.controllerId;
          data.gatewayId = this.gatewayId;
          if (data?.media?.length > 0) {
            data.isVideo =
              data.media.filter(med => med.type.toLowerCase() === VIDEO && med.fileUrl !== '' && med.fileUrl !== null)?.length > 0;
          }
        }
      });
      this.popOverCameraDetails = this.selectedPopOverIndex !== -1 ? this.gridData[this.selectedPopOverIndex] : null;
      if (this.isOpen) {
        this.onEventClick(this.selectedPopOverIndex);
      }
    }
    if (this.paginator && this.paginatedData) {
      this.paginator.pageIndex = this.paginatedData.pageIndex;
      this.paginator.pageSize = this.paginatedData.pageSize;
    }
  }

  ngAfterViewInit(): void {
    if (this.paginator && this.paginatedData) {
      this.paginator.pageIndex = this.paginatedData?.pageIndex;
      this.paginator.pageSize = this.paginatedData?.pageSize;
    }
  }

  public downloadImage(cameraDetails: EventDetails): void {
    const urls = cameraDetails?.media.filter((url: Media) => url.fileUrl);
    if (urls.length > 0 && this.gatewayId && this.controllerId) {
      this.cameraProfileservice
        .downloadMediaAsZip(this.gatewayId, this.controllerId, [cameraDetails.eventId])
        .pipe(
          tap((data: string) => {
            if (data && data !== '') {
              this.dashboardService.downloadZipFile(data, this.controllerId);
            } else {
              this.messageService.add({ severity: SlbSeverity.Error, summary: FILE_DOWNLOAD_ERROR });
            }
          }),
          catchError(err => {
            this.messageService.add({ severity: SlbSeverity.Error, summary: FILE_DOWNLOAD_ERROR });
            throw err;
          }),
          takeUntil(this.destroyed)
        )
        .subscribe();
    } else {
      this.messageService.add({ severity: SlbSeverity.Error, summary: FILE_DOWNLOAD_ERROR });
    }
  }

  public checkBoxSelection(): void {
    const filteredFiles: EventDetails[] = this.gridData.filter((data: EventDetails) => data && data.isChecked);
    this.selectedData.emit(filteredFiles);
  }

  public onImageClick(eventDetails: EventDetails, index: number): void {
    this.isOpen = !this.isOpen;
    this.selectedPopOverIndex = index;
    const mapData: EventDetails = eventDetails;
    mapData.gatewayId = this.gatewayId;
    mapData.controllerId = this.controllerId;
    const pageEvent = this.paginatedData
      ? this.paginatedData
      : ({
          pageIndex: 0,
          previousPageIndex: 0,
          pageSize: 10,
          length: this.totalNoRecords,
        } as PageEvent);
    this.checkNext(pageEvent);
    this.checkPrevious(pageEvent);
    this.popOverCameraDetails = mapData;
  }

  public onEventClick(event: number): void {
    const action = event === this.selectedPopOverIndex - 1 ? 'prev' : 'next';
    this.selectedPopOverIndex = event;
    const pageEvent = this.paginatedData
      ? this.paginatedData
      : ({
          pageIndex: 0,
          previousPageIndex: 0,
          pageSize: 10,
          length: this.totalNoRecords,
        } as PageEvent);
    this.checkNext(pageEvent);
    this.checkPrevious(pageEvent);
    if (this.gridData[this.selectedPopOverIndex]) {
      const data: EventDetails = this.gridData[this.selectedPopOverIndex];
      this.popOverCameraDetails = data;
    } else if (this.hasNext) {
      pageEvent.pageIndex = action === 'next' ? pageEvent.pageIndex + 1 : pageEvent.pageIndex - 1;
      this.selectedPopOverIndex = action === 'next' ? 0 : pageEvent.pageSize - 1;
      this.pageData.emit(pageEvent);
    }
  }

  public checkNext(pageEvent: PageEvent): void {
    this.hasNext = pageEvent.pageIndex * pageEvent.pageSize + (this.selectedPopOverIndex + 1) >= this.totalNoRecords ? false : true;
  }

  public checkPrevious(pageEvent: PageEvent): void {
    this.hasPrevious = this.selectedPopOverIndex <= 0 && pageEvent.pageIndex === 0 ? false : true;
  }

  public pageEvent(event: PageEvent): void {
    this.selectedData.emit([]);
    this.pageData.emit(event);
  }

  ngOnDestroy(): void {
    this.destroyed.next(true);
  }
}
