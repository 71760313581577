import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Media } from '../models/eventDetails';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent {
  @Input({ required: true }) media: Media[] = [];
  @Input({ required: true }) showIndicators = true;
  @Input({ required: true }) selectedIndex = 0;
  @Input({ required: true }) showControls = true;
  @Output() activeIndex = new EventEmitter<number>();
  public sessionToken: string;

  constructor() {
    this.sessionToken = sessionStorage.getItem('access_token') ?? '';
  }

  public setSelectedIndex(index: number): void {
    this.selectedIndex = index;
    this.activeIndex.emit(this.selectedIndex);
  }

  onPrevClick(): void {
    if (this.selectedIndex !== 0) {
      this.selectedIndex--;
      this.activeIndex.emit(this.selectedIndex);
    }
  }

  onNextClick(): void {
    if (this.selectedIndex !== this.media.length - 1) {
      this.selectedIndex++;
      this.activeIndex.emit(this.selectedIndex);
    }
  }
}
