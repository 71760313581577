<div id="vi_camera_profile_alerts_grid_container">
  <p *ngIf="gridData && gridData.length === 0; else showGrid" class="no-data" id="vi_camera_profile_alerts_grid_no-data">
    No data available
  </p>
  <ng-template #showGrid>
    <mat-card class="alert-grid-card" *ngFor="let data of gridData; let i = index">
      <mat-card-header class="d-block" id="{{ 'vi_camera_profile_alerts_grid_card_' + i }}">
        <mat-card-title id="{{ 'vi_camera_profile_alerts_grid_card_title_' + i }}">
          <div>
            <mat-checkbox class="checkbox" [(ngModel)]="data.isChecked" (change)="checkBoxSelection()"></mat-checkbox>
            <span><mat-icon svgIcon="download" class="top-icons cursor-pointer" (click)="downloadImage(data)">Download</mat-icon></span>
            <span *ngIf="data.isVideo" class="videos top-icons"></span>
            <span *ngIf="data.isReviewed"><mat-icon svgIcon="check" class="active-icon top-icons">check</mat-icon></span>
          </div>
          <p class="event-time" id="{{ 'vi_camera_profile_alerts_grid_card_timestamp_' + i }}">
            Event time: {{ data.timestamp | date : 'MM/dd/yyyy HH:mm:ss a' : currentZone }}
          </p>
        </mat-card-title>
      </mat-card-header>
      <img
        *ngIf="data.thumbnailFileUrl; else errorImage"
        mat-card-image
        #eventImage
        class="cursor-pointer images"
        id="{{ 'vi_camera_profile_alerts_grid_card_image_' + i }}"
        src="{{ data.thumbnailFileUrl }}/?token={{ sessionToken }}"
        alt="IMAGE"
        (error)="eventImage.src = '../../assets/no-preview.svg'"
        (click)="onImageClick(data, i)" />
      <ng-template #errorImage>
        <img
          mat-card-image
          class="cursor-pointer images no-prev"
          id="{{ 'vi_camera_profile_alerts_grid_card_image_error_' + i }}"
          src="../../../assets/no-preview.svg"
          (click)="onImageClick(data, i)" />
      </ng-template>
      <mat-card-content>
        <div class="rig-state" id="{{ 'vi_camera_profile_alerts_grid_card_rig_' + i }}">
          <p>
            <span
              *ngIf="data?.rigState === 'Red zone static' || (data?.peopleInsideRedZone === 0 && data?.ppeViolations === 0); else rigData">
              <span class="bg-setting people-redzone"></span>
              <strong>Red zone static</strong>
            </span>
            <ng-template #rigData>
              <p class="camera-info">
                <span class="bg-setting" ngClass="{{ data && data.peopleInsideRedZone > 0 ? 'people-redzone' : 'ppe-violations' }}"></span>
                <span class="label">{{ data && data.peopleInsideRedZone > 0 ? 'Rig floor Red zone state: ' : 'Missing: ' }}</span>
                <span class="value" matTooltip="{{ data.rigState }}">{{ data.rigState }}</span>
              </p>
            </ng-template>
          </p>
        </div>
      </mat-card-content>
    </mat-card>
    <div class="pagination-wrapper">
      <mat-paginator
        id="vi_camera_profile_alerts_grid_card_pagination"
        #paginator
        [length]="totalNoRecords"
        showFirstLastButtons
        [pageSizeOptions]="[10, 20, 30]"
        (page)="pageEvent($event)"
        class="camera-profile-grid-pagination">
      </mat-paginator>
    </div>
  </ng-template>
</div>
<mat-drawer #drawer class="sidenav" fixedInViewport="true" position="end" [opened]="isOpen">
  <app-latest-camera-popover
    *ngIf="popOverCameraDetails"
    [eventIndex]="selectedPopOverIndex"
    [hasNext]="hasNext"
    [hasPrevious]="hasPrevious"
    [cameraDetails]="popOverCameraDetails"
    (nextPrevEvent)="onEventClick($event)"
    (closeSideInfo)="drawer.close(); popOverCameraDetails = null; isOpen = false"></app-latest-camera-popover>
</mat-drawer>
