export const environment = {
  production: true,
  apiBaseUrl: 'https://evt-demo.agorava.cloud.slb-ds.com',
  isAutomationTesting: false,
  appConfigApiUrl: '',
  identityProviderUrl:'https://p4d.csi.cloud.slb-ds.com/v2',
  identityProviderClientKey: 'b617ae303e4443bf5e87dbf41e696257',
  identityProviderClientSecret:'a0456973d78740849486f38b2c897e540f16ccf675e',
  identityProviderRedirectUrl: 'https://evt-demo.agorava.cloud.slb-ds.com/login/logincallback'
};
