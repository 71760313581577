<slb-navigation-framework
  #framework
  [applicationName]="'Agora  &nbsp; | &nbsp; Vision Intelligence'"
  [displayViewModeButtons]="false"
  [hasSidenav]="true"
  [notifications]="true"
  [showBackButton]="showBackButton"
  [secondaryNavigationItems]="secondaryNavItems"
  (secondaryNavigationSelected)="secondaryNavigationSelected($event)"
  (sideNavOpenedChange)="setResizeEvent($event)">
  <slb-framework-toolbar>
    <ng-content *ngTemplateOutlet="toolbar"></ng-content>
  </slb-framework-toolbar>
  <slb-framework-content>
    <ng-content *ngTemplateOutlet="content"></ng-content>
  </slb-framework-content>

  <slb-framework-page-header
    *ngIf="showHeader && authorized"
    [showBackButton]="showBackButton"
    [pageTitle]="pageTitle"
    [ngClass]="{ 'new-page-header-height': panelOpenState }">
    <div class="time-zone" *ngIf="!pageTitle?.includes('Dashboard')">
      <div class="top-icon">
        <mat-icon
          svgIcon="oil-bitumen"
          *ngIf="cameraWorkflow.toLowerCase()"
          class="icon-width"
          [ngClass]="{
            active: cameraStatus === '0',
            inactive: cameraStatus === '1',
            disconnected: cameraStatus === '2'
          }"></mat-icon>
      </div>
      <mat-form-field
        slbFormField
        [matTooltip]="currentZoneArea + ' ' + currentTimezone"
        [ngClass]="{ 'update-zone': isCameraProfilePage, camera: cameraName.toLowerCase().includes('ppe') === true || isCameraPage }">
        <input matInput readonly aria-readonly="true" [(ngModel)]="currentZoneArea + ' ' + currentTimezone" id="agora_vi_timezone_input" />
      </mat-form-field>
    </div>
    <div class="country" *ngIf="showCountry">
      <mat-form-field slbFormField>
        <input matInput readonly aria-readonly="true" [(ngModel)]="country" id="agora_vi_country_input" />
      </mat-form-field>
    </div>

    <button class="zone-config-btn" *ngIf="cameraWorkflow.toLowerCase() === 'redzone'" slb-button (click)="openZoneConfigurationPopUp()">
      Zone definition
    </button>

    <button
      class="zone-setting-btn"
      *ngIf="cameraWorkflow.toLowerCase() === 'redzone'"
      slb-button
      (click)="openZoneSettingPopUp()"
      [disabled]="disableZoneSetting">
      Zone setting
    </button>
    <button (click)="panelOpenState = !panelOpenState" class="toggle-icon" *ngIf="cameraName">
      <mat-icon svgIcon="arrow-down-5" *ngIf="panelOpenState" class="down-icon" id="agora_vi_arrow_down_icon"></mat-icon>
      <mat-icon svgIcon="arrow-up-5" *ngIf="!panelOpenState" id="agora_vi_arrow_up_icon"></mat-icon>
    </button>
    <div class="camera-details">
      <app-camera-profile-header *ngIf="panelOpenState"></app-camera-profile-header>
    </div>
  </slb-framework-page-header>

  <ng-template #toolbar>
    <span class="last-refreshed" *ngIf="authorized"> Last refresh at: {{ refreshedDate | date : 'medium' }}</span>
    <span>
      <mat-icon svgIcon="refresh" *ngIf="authorized" class="refresh-icon" id="agora_vi_refresh_icon" (click)="navigateToSelf()"></mat-icon>
    </span>
    <slb-logout *ngIf="userName" [userName]="userName" (logout)="logout()">
      <p class="toolbar-email">{{ userEmail }}</p>
      <p class="toolbar-info">Application version: BuildVersion</p>
    </slb-logout>
  </ng-template>

  <ng-template #content>
    <router-outlet></router-outlet>
  </ng-template>
</slb-navigation-framework>
