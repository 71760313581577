import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { PopUpService } from '../shared/services/pop-up.service';
import { EventDetails, Media } from '../shared/models/eventDetails';
import { CameraStatus } from '../shared/constants/enum';
import { Subject, catchError, filter, switchMap, takeUntil, tap } from 'rxjs';
import { FILE_DOWNLOAD_ERROR } from '../shared/constants/camera-profile-constant';
import { MessageService, SlbSeverity } from '@slb-dls/angular-material/notification';
import { TimeZoneService } from '../shared/services/time-zone.service';
import { TimeZone } from '../shared/models/dashabordAlertData';
import { CameraConstants } from '../shared/constants/camera-constant';
import { DashboardService } from '../shared/services/dashboard.service';
import { CameraProfileService } from '../shared/services/camera-profile.service';
import { LoginService } from '@agora/agora-ui-library';

@Component({
  selector: 'app-latest-camera-popover',
  templateUrl: './latest-camera-popover.component.html',
  styleUrls: ['./latest-camera-popover.component.scss'],
})
export class LatestCameraPopoverComponent implements OnChanges, AfterViewInit, OnDestroy {
  @ViewChild('videoPlayer') videoPlayer: ElementRef;
  @Input() cameraDetails: EventDetails;
  @Input() hasNext = true;
  @Input() hasPrevious = false;
  @Input() eventIndex = 0;
  @Output() closeSideInfo = new EventEmitter<string>();
  @Output() nextPrevEvent = new EventEmitter<number>();
  public isImage: boolean;
  public isFullscreen: boolean;
  public currentIndex = 0;
  public currentImageInfo: Media | undefined | null;
  public cameraName: string;
  public cameraStatus: CameraStatus;
  public currentZone = '';
  public media: Media[] = [];
  public currentEventIndex = 0;
  public eventType = '';
  public noImagePreview = false;
  public isSideNavOpen = false;
  public sessionToken: string;
  private destroyed = new Subject();

  constructor(
    private popService: PopUpService,
    private timeZoneService: TimeZoneService,
    private messageService: MessageService,
    private dashboardService: DashboardService,
    private cameraProfileService: CameraProfileService,
    private loginService: LoginService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.sessionToken = sessionStorage.getItem('access_token') ?? '';
    if (changes['cameraDetails'] && changes['cameraDetails'].currentValue) {
      this.currentIndex = 0;
      this.currentEventIndex = this.eventIndex;
      this.media = [];
      if (this.cameraDetails.media.length > 0) {
        const mediaData = this.cameraDetails.media.filter(m => m.type === 'image' || (m.type === 'video' && m.fileUrl));
        this.media = mediaData;
        this.currentImageInfo = this.cameraDetails.media[this.currentIndex];
        this.noImagePreview = this.cameraDetails?.media.filter(m => m?.fileUrl).length > 0 ? false : true;
        if (this.media.length === 2) {
          const imgData = mediaData.filter(data => data.type === 'image');
          const vidData = mediaData.filter(data => data.type === 'video');
          this.media = JSON.parse(JSON.stringify([...imgData, ...vidData]));
          if (imgData?.[0]?.fileUrl?.includes('mp4')) {
            this.media[1].fileUrl = imgData[0].fileUrl;
          }
          if (!vidData?.[0]?.fileUrl?.includes('mp4')) {
            this.media[0].fileUrl = vidData[0].fileUrl;
          }
          if (!this.media[0].fileUrl && this.media[0].type === 'image') {
            this.currentIndex = 1;
          }
          this.validateMediaUrl();
        }
      } else {
        this.media.push({ type: 'image', fileUrl: '' });
        this.noImagePreview = this.media?.filter(m => m?.fileUrl && m.fileUrl !== '')?.length > 0 ? false : true;
      }

      if (this.isFullscreen) {
        this.isFullscreen = !this.noImagePreview ? this.isFullscreen : !this.isFullscreen;
      }
      if (this.cameraDetails?.cameraStatus === CameraStatus.Active) {
        this.cameraStatus = CameraStatus.Active;
      } else if (this.cameraDetails?.cameraStatus === CameraStatus.Inactive) {
        this.cameraStatus = CameraStatus.Inactive;
      } else {
        this.cameraStatus = CameraStatus.Disconnected;
      }
      if (this.cameraDetails.peopleInsideRedZone === 0 && this.cameraDetails.ppeViolations && this.cameraDetails.ppeViolations > 0) {
        this.eventType = CameraConstants.PpeViolation;
      } else {
        this.eventType = CameraConstants.RedZoneViolation;
      }
    }
  }

  ngAfterViewInit(): void {
    this.isFullscreen = false;
    this.timeZoneService.timeZoneDetails$
      .pipe(
        filter((zone: TimeZone) => !!Object.keys(zone).length),
        tap((_zone: TimeZone) => (this.currentZone = this.timeZoneService.getTimeZone())),
        switchMap(() =>
          this.cameraProfileService.sideNavClosed$.pipe(
            tap((value: boolean) => {
              this.isSideNavOpen = value;
            })
          )
        ),
        takeUntil(this.destroyed)
      )
      .subscribe();
  }

  public onClose(): void {
    this.isFullscreen = false;
    this.closeSideInfo.emit();
  }

  public onNextPrevClick(type: string): void {
    if (type === CameraConstants.next) {
      this.currentEventIndex = this.currentEventIndex + 1;
    } else {
      this.currentEventIndex = this.currentEventIndex - 1;
    }
    this.nextPrevEvent.emit(this.currentEventIndex);
    this.currentIndex = 0;
  }

  public downloadImages(): void {
    const eventId = this.cameraDetails.eventId;
    if (this.cameraDetails?.gatewayId && this.cameraDetails?.controllerId && !this.noImagePreview) {
      this.cameraProfileService
        .downloadMediaAsZip(this.cameraDetails.gatewayId, this.cameraDetails.controllerId, [eventId])
        .pipe(
          tap((data: string) => {
            if (data && data !== '' && this.cameraDetails?.controllerId) {
              this.dashboardService.downloadZipFile(data, this.cameraDetails?.controllerId);
            } else {
              this.messageService.add({ severity: SlbSeverity.Error, summary: FILE_DOWNLOAD_ERROR });
            }
          }),
          catchError(err => {
            this.messageService.add({ severity: SlbSeverity.Error, summary: FILE_DOWNLOAD_ERROR });
            throw err;
          }),
          takeUntil(this.destroyed)
        )
        .subscribe();
    } else {
      this.messageService.add({ severity: SlbSeverity.Error, summary: FILE_DOWNLOAD_ERROR });
    }
  }

  public openEmail(): void {
    const a = document.createElement('a');
    a.href = 'mailto:';
    a.click();
  }

  public toggleFullscreen(): void {
    this.isFullscreen = !this.isFullscreen;
  }

  public async openReview(cameraDetails: EventDetails): Promise<void> {
    if (cameraDetails) {
      const arrEventDetails: EventDetails[] = [cameraDetails];
      await this.popService.openEditReviewConfiguration(arrEventDetails);
    }
  }

  public getCurrentIndex(event: { activeSlideIndex: number; totalSlides: number }): void {
    this.currentIndex = event.activeSlideIndex;
  }

  public setCurrentIndex(event: number): void {
    this.currentIndex = event;
  }

  ngOnDestroy(): void {
    this.destroyed.next(true);
  }

  private validateMediaUrl(): void {
    const mediaData = this.media;
    mediaData.forEach(el => {
      if (el.fileUrl) {
        const requestUrl = new URL(el.fileUrl);
        requestUrl.searchParams.append('token', this.sessionToken);
        fetch(requestUrl)
          .then(res => {
            if (res.status !== 200) {
              /** When token is expired, redirect to login page*/
              if (res.status === 403) {
                this.loginService.login();
              }
              this.removeInvalidMedia(el.type);
            }
          })
          .catch(() => {
            this.removeInvalidMedia(el.type);
          });
      }
    });
  }

  private removeInvalidMedia(type: string): void {
    if (type === 'video') {
      this.media = this.media.filter(m => m.type !== type);
    } else {
      const index = this.media.findIndex(m => m.type === type);
      if (index !== -1) {
        this.media[index].fileUrl = '';
      }
    }
    if (this.media.length > 1) {
      this.noImagePreview = this.media.filter(m => m?.fileUrl && m.fileUrl !== '').length > 0 ? false : true;
    }
  }
}
