import { AlertFilter } from '../models/alertFilter';
import { Chart } from '../models/chart';

export class DashboardConstants {
  public static dateRanges = ['Today', 'Hour', 'Week', 'Month'];
  public static defaultView = 'grid';
  public static defaultTimeRange = 'Hour';
  public static statusList = ['Inactive', 'Active', 'Disconnect'];
  public static timezoneList = ['UTC+05:00 Asia/Kolkata'];
  public static countryList = ['India'];
  public static defaultZone = 'UTC+05:00 Asia/Kolkata';
  public static defaultCountry = 'India';
  public static RECORDS_PER_PAGE = 10;
  public static PAGE_NUMBER = 1;
  public static REDZONE = 'redzone';
  public static REDZONE_SERVICE = 'RedZone';
  public static defaultStatusList = [
    {
      id: -1,
      status: 'All',
    },
    {
      id: 0,
      status: 'Active',
    },
    {
      id: 1,
      status: 'Inactive',
    },
    {
      id: 2,
      status: 'Disconnected',
    },
  ];

  public static gatewayChartConfig: Chart = {
    labels: ['Active', 'Inactive', 'Disconnected'],
    datasets: [
      {
        data: [],
        backgroundColor: ['#3097A7', '#EE5E64', '#C7D0D8'],
        borderColor: ['#3097A7', '#EE5E64', '#C7D0D8'],
        borderWidth: 1,
        hoverBorderColor: ['#3097A7', '#EE5E64', '#C7D0D8'],
        hoverBackgroundColor: ['#3097A7', '#EE5E64', '#C7D0D8'],
        pointBackgroundColor: ['#3097A7', '#EE5E64', '#C7D0D8'],
        pointBorderColor: ['#fff', '#fff', '#fff', '#C7D0D8'],
        pointHoverBackgroundColor: ['#3097A7', '#EE5E64', '#C7D0D8'],
        pointHoverBorderColor: ['#3097A7', '#EE5E64', '#C7D0D8'],
      },
    ],
  };

  public static cameraChartConfig: Chart = {
    labels: ['Active', 'Inactive', 'Disconnected'],
    datasets: [
      {
        data: [],
        backgroundColor: ['#3097A7', '#EE5E64', '#C7D0D8'],
        borderColor: ['#3097A7', '#EE5E64', '#C7D0D8'],
        borderWidth: 1,
        hoverBorderColor: ['#3097A7', '#EE5E64', '#C7D0D8'],
        hoverBackgroundColor: ['#3097A7', '#EE5E64', '#C7D0D8'],
        pointBackgroundColor: ['#3097A7', '#EE5E64', '#C7D0D8'],
        pointBorderColor: ['#fff', '#fff', '#fff', '#C7D0D8'],
        pointHoverBackgroundColor: ['#3097A7', '#EE5E64', '#C7D0D8'],
        pointHoverBorderColor: ['#3097A7', '#EE5E64', '#C7D0D8'],
      },
    ],
  };

  public static voilationChartConfig: Chart = {
    labels: ['Reviewed', 'Not Reviewed'],
    datasets: [
      {
        data: [],
        backgroundColor: ['#3097A7', '#FEE58A'],
        borderColor: ['#3097A7', '#FEE58A'],
        borderWidth: 1,
        hoverBorderColor: ['#3097A7', '#FEE58A'],
        hoverBackgroundColor: ['#3097A7', '#FEE58A'],
        pointBackgroundColor: ['#3097A7', '#FEE58A'],
        pointBorderColor: ['#fff', '#fff', '#fff'],
        pointHoverBackgroundColor: ['#3097A7', '#FEE58A'],
        pointHoverBorderColor: ['#3097A7', '#FEE58A'],
      },
    ],
  };

  public static mockWorkflows = ['Red Zone', 'PPE'];
  public static dashabordChartAPIError = 'Error while receiving chart details.';
  public static dashabordAlertsAPIError = 'Error while receiving alert details.';
  public static dashabordAssetHierarchyAPIError = 'Error while receiving asset hierarchy details.';
  public static NOCAMERAEVENT = 'No events detected for';
  public static active = 'Active';
  public static inactive = 'Inactive';
  public static disconnected = 'Disconnected';
  public static reviewed = 'Reviewed';
  public static notReviewed = 'Not Reviewed';

  public static RIG_DETAILS = {
    BLOCK_MOVING_UP: 'Block Moving Up',
    BLOCK_MOVING_DOWN: 'Block Moving Down',
    MUD_CIRCULATION: 'Mud Circulation',
    DRILLING_ROTATION: 'Drilling Rotation',
    REDZONE_ON: 'RedZone On',
    NA: 'NA',
  };

  public static defaultFilter: AlertFilter = {
    rigId: -1,
    workflow: '',
    gatewayStatus: -1,
    cameraStatus: -1,
    pageNumber: DashboardConstants.PAGE_NUMBER,
    recordsPerPage: DashboardConstants.RECORDS_PER_PAGE,
  };
  public static INITIAL = 'initial';
  public static UPDATE = 'update';
  public static EVENTTYPE = ['PPE events', 'Red zone events'];
}
