import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DashboardComponent } from './dashboard/dashboard.component';
import { CameraComponent } from './camera/camera.component';
import { CameraProfileDashboardComponent } from './camera-profile/camera-profile-dashboard/camera-profile-dashboard.component';
import { LoginComponent } from '@agora/agora-ui-library';
import { AuthGuard } from './authentication/auth.guard';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';

const routes: Routes = [
  {
    path: 'login/logincallback',
    component: LoginComponent,
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    component: DashboardComponent,
    data: {
      title: 'Dashboard',
      showBackButton: false,
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'camera',
    component: CameraComponent,
    data: {
      title: 'Cameras',
      showBackButton: false,
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'zone',
    loadChildren: () => import('./zone-manager/zone-manager.module').then(m => m.ZoneManagerModule),
    data: {
      title: 'Zone Manager',
      showHeader: true,
      showBackButton: false,
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'home/:cameraName/:cameraStatus/:gateway/:controllerId/:rigName',
    component: CameraProfileDashboardComponent,
    data: {
      title: 'Camera',
      showBackButton: true,
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
    data: {
      title: '',
      showBackButton: false,
    },
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
