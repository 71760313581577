import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { MediaData } from '../models/eventDetails';
import { ApiConstants } from '../constants/api-constant';
import { RequestId, ZoneConfigurationDto, ZoneConfigurationResponse } from '../models/zone-configuration';
import { ZoneSetting } from '../models/zoneSetting';

@Injectable({
  providedIn: 'root',
})
export class ZoneService {
  private baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = environment.apiBaseUrl;
  }

  public getRequestImage(gatewayId: string, controllerId: string): Observable<RequestId> {
    return this.http.get<RequestId>(`${this.baseUrl}${ApiConstants.BASEURL}request-current-image/${gatewayId}/${controllerId}`);
  }

  public getRequestVideo(gatewayId: string, controllerId: string): Observable<RequestId> {
    return this.http.get<RequestId>(`${this.baseUrl}${ApiConstants.BASEURL}request-current-video/${gatewayId}/${controllerId}`);
  }

  public getZoneConfig(controllerId: string): Observable<ZoneConfigurationDto> {
    return this.http.get<ZoneConfigurationDto>(`${this.baseUrl}${ApiConstants.BASEURL}get-zone-config/${controllerId}`);
  }

  public getZoneImage(mediaRequestId: string): Observable<MediaData> {
    return this.http.get<MediaData>(`${this.baseUrl}${ApiConstants.BASEURL}get-zone-config-media/${mediaRequestId}`);
  }

  public saveZoneConfig(zoneDetails: ZoneConfigurationDto): Observable<ZoneConfigurationResponse> {
    return this.http.post<ZoneConfigurationResponse>(`${this.baseUrl}${ApiConstants.BASEURL}save-zone-config`, zoneDetails);
  }

  public getZoneSetting(zoneId: number): Observable<ZoneSetting> {
    return this.http.get<ZoneSetting>(`${this.baseUrl}${ApiConstants.BASEURL}zone-setting/${zoneId}`);
  }

  public saveZoneSetting(zoneConfig: any, zoneId: number): Observable<any> {
    if (zoneId) {
      return this.http.patch<any>(`${this.baseUrl}${ApiConstants.BASEURL}zone-setting/${zoneId}`, zoneConfig);
    } else {
      return this.http.post<any>(`${this.baseUrl}${ApiConstants.BASEURL}zone-setting`, zoneConfig);
    }
  }

  public getCommandStatus(id: number, commandId: string, controllerId: string, groupId: string): Observable<string> {
    return this.http.get<string>(`${this.baseUrl}${ApiConstants.BASEURL}command_status/${id}/${commandId}/${controllerId}/${groupId}`);
  }
}
