import { Component, OnDestroy, ViewEncapsulation, OnInit, ChangeDetectorRef } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, RoutesRecognized } from '@angular/router';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { NavItem, isDefined } from '@slb-dls/angular-material/shared';
import { SlbNotificationItem } from '@slb-dls/angular-material/notifications-panel';
import { secondaryNavItems } from './navigation-items';
import { NavDetails } from './shared/models/Nav';
import { MessageService, SlbSeverity } from '@slb-dls/angular-material/notification';
import { PopUpService } from './shared/services/pop-up.service';
import { LoginService } from '@agora/agora-ui-library';
import { TimeZoneService } from './shared/services/time-zone.service';
import { TimeZone } from './shared/models/dashabordAlertData';
import { CameraProfileService } from './shared/services/camera-profile.service';
import { ZoneConfig } from './shared/models/zoneSetting';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnDestroy, OnInit {
  public notificationCount = 10;
  public notificationItems: SlbNotificationItem[] = [];
  public showBackButton: boolean;
  public showHeader = false;
  public pageTitle = '';
  public secondaryNavItems = secondaryNavItems;
  public options = ['EST', 'PST'];
  public cameraName: string;
  public refreshedDate = new Date();
  public currentTimezone = '';
  public refreshedTimezone = '';
  public userName!: string;
  public userEmail!: string;
  public currentZoneArea: string;
  public currentZoneTime: string;
  public country = 'All';
  public showCountry = false;
  public isCameraPage = false;
  public isCameraProfilePage: boolean;
  public disableZoneSetting: boolean;
  public cameraWorkflow: string;
  public panelOpenState: boolean;
  public authorized: boolean;
  public cameraStatus: string;

  private routerSubscription = Subscription.EMPTY;
  private timezoneSubscription = Subscription.EMPTY;
  private reLoadSubscription = Subscription.EMPTY;
  private zoneSettingSubscription = Subscription.EMPTY;
  constructor(
    private router: Router,
    matIconRegistry: MatIconRegistry,
    domSanitizer: DomSanitizer,
    private popUpService: PopUpService,
    private messageService: MessageService,
    private loginService: LoginService,
    private timeZoneService: TimeZoneService,
    private cameraProfileService: CameraProfileService,
    private cd: ChangeDetectorRef
  ) {
    matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg-symbols.svg'));
  }

  ngOnInit(): void {
    this.panelOpenState = false;
    this.authorized = true;
    this.routerSubscription = this.router.events
      .pipe(
        filter(e => e instanceof RoutesRecognized),
        map(e => e as RoutesRecognized),
        tap(e => {
          this.onRouteChange(e);
        })
      )
      .subscribe();

    this.timezoneSubscription = this.timeZoneService.timeZoneDetails$
      .pipe(
        tap((timeZoneDetails: TimeZone) => {
          this.currentTimezone = timeZoneDetails.value;
          this.currentZoneArea = timeZoneDetails?.label;
          this.refreshedDate = this.timeZoneService.getDateandTime();
        })
      )
      .subscribe();

    this.loginService.isUserLoggedIn$
      .pipe(
        tap(() => {
          this.userEmail = this.loginService.getUserInfo()?.email ?? '';
          this.userName = this.loginService.getUserInfo()?.name ?? '';
        })
      )
      .subscribe();

    this.zoneSettingSubscription = this.cameraProfileService.selectedZoneId$
      .pipe(
        tap((params: ZoneConfig) => {
          if (params) {
            this.disableZoneSetting = params.deviceId && params.groupdId ? false : true;
          } else {
            this.disableZoneSetting = true;
          }
        }),
        switchMap(() => this.cameraProfileService.cameraWorkflow$.pipe(tap((value: string) => (this.cameraWorkflow = value))))
      )
      .subscribe();
    this.chooseSecondaryNav();
  }

  public setResizeEvent(event: boolean): void {
    this.cameraProfileService.sideNavClosed.set(event);
  }

  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
    this.timezoneSubscription.unsubscribe();
    this.reLoadSubscription.unsubscribe();
    this.zoneSettingSubscription.unsubscribe();
  }

  public secondaryNavigationSelected(event: NavDetails): void {
    if (event) {
      const item = event.selectedItem;
      this.secondaryNavItems.forEach((navItem: NavItem) => (navItem.active = navItem.routerLink === item.routerLink ? true : false));
      if (event?.selectedItem?.routerLink) {
        this.router.navigateByUrl(event?.selectedItem?.routerLink as unknown as string);
      }
    }
  }

  public async openZoneConfigurationPopUp(): Promise<void> {
    const confirmation = await this.popUpService.openZoneConfiguration(this.cameraStatus);
    if (confirmation) {
      this.messageService.add({ severity: SlbSeverity.Success, summary: 'Success', detail: 'Zone definition saved successfully.' });
    }
  }

  public async openZoneSettingPopUp(): Promise<void> {
    const confirmation = await this.popUpService.openZoneSetting(this.cameraStatus);
    if (confirmation) {
      this.messageService.add({ severity: SlbSeverity.Success, summary: 'Success', detail: 'Zone setting saved successfully.' });
    }
  }

  public logout(): void {
    this.loginService.logout();
    sessionStorage.clear();
  }

  public navigateToSelf(): void {
    const actualRoute = this.router.url;
    let defaultRoute = '/';
    if (this.router.url === '/home') {
      defaultRoute = '/camera';
    }
    this.router.navigateByUrl(defaultRoute, { skipLocationChange: true }).then(() => {
      this.router.navigate([actualRoute]);
    });
  }

  private chooseSecondaryNav(): void {
    const url = document.location.href;
    if (url && !url.includes('login') && url.split('/')?.[3] !== '' && this.secondaryNavItems?.length) {
      this.secondaryNavItems.forEach((navItem: NavItem) => (navItem.active = url.split('/')?.[3] === navItem.routerLink ? true : false));
    }
  }

  private onRouteChange(event: RoutesRecognized): void {
    this.panelOpenState = false;
    this.refreshedDate = this.timeZoneService.getDateandTime();
    this.showCountry = false;
    this.isCameraPage = false;
    this.isCameraProfilePage = false;
    this.country = 'All';
    const data: any = event?.state?.root?.firstChild?.data;
    this.showHeader = isDefined(data.showHeader) ? data.showHeader : true;
    this.showBackButton = isDefined(data.showBackButton) ? data.showBackButton : false;
    const currentUrl = event.url;
    if (!currentUrl?.includes('login') && !currentUrl?.includes('unauthorized')) {
      sessionStorage.setItem('currentUrl', currentUrl);
    }
    /** When unauthorized page comes, needs to hide the secondary navigation items  */
    if (currentUrl?.includes('unauthorized')) {
      this.authorized = false;
      this.secondaryNavItems = [];
    } else {
      this.authorized = true;
      this.secondaryNavItems = secondaryNavItems;
    }
    if (data?.title?.toLowerCase() === 'camera') {
      this.isCameraProfilePage = true;
      this.panelOpenState = true;
      const paramData = event.state.root.firstChild?.params;
      if (paramData?.cameraName) {
        this.cameraName = paramData.cameraName;
      }
      if (paramData?.cameraStatus) {
        this.cameraStatus = paramData.cameraStatus;
      }
    } else {
      sessionStorage.removeItem('cameraName');
      this.cameraName = '';
      this.cameraWorkflow = '';
    }
    if (data?.title?.toLowerCase() === 'cameras') {
      this.isCameraPage = true;
      this.showCountry = true;
      const country = sessionStorage.getItem('country');
      if (country) {
        this.country = country === '' ? 'All' : country;
      }
      const item = this.secondaryNavItems;
      this.secondaryNavItems.forEach((navItem: NavItem) => (navItem.active = navItem.label?.toLowerCase() === 'camera'));
      this.secondaryNavItems = [...item];
      this.cd.detectChanges();
    }
    this.pageTitle = data.title === 'Camera' ? this.cameraName : data.title;
  }
}
