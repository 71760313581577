import { Component, Inject, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessageService, SlbSeverity } from '@slb-dls/angular-material/notification';
import { EventReviewConstants } from '../../shared/constants/event-review-constants';
import { EventDetails } from '../../shared/models/eventDetails';
import { CameraProfileService } from '../../shared/services/camera-profile.service';
import { Subject, catchError, of, takeUntil, tap } from 'rxjs';
import { EventDetailsDto } from '../../shared/models/eventDetailsDto';
import { MULTIEVENTDATA } from '../../shared/constants/camera-profile-constant';

@Component({
  selector: 'app-event-review',
  templateUrl: './event-review.component.html',
  styleUrls: ['./event-review.component.scss'],
})
export class EventReviewComponent implements OnDestroy {
  public eventForm: FormGroup = this.fb.group({
    eventId: [''],
    flag: ['', Validators.required],
    category: ['', Validators.required],
    comment: [''],
  });

  public flagsData = EventReviewConstants.FlagsData;
  public categories = EventReviewConstants.Categories;
  public controllerId: string;
  public gatewayId: string;
  public eventData: EventDetails;
  private destroyed = new Subject();

  constructor(
    private fb: FormBuilder,
    private messageService: MessageService,
    public dialogRef: MatDialogRef<EventReviewComponent>,
    private cameraProfileService: CameraProfileService,
    @Inject(MAT_DIALOG_DATA) private eventDetails: EventDetails[]
  ) {
    if (this.eventDetails.length === 1) {
      this.eventData = structuredClone(this.eventDetails[0]);
    } else {
      this.eventData = structuredClone(MULTIEVENTDATA);
    }
  }

  ngOnDestroy(): void {
    this.destroyed.next(true);
    this.destroyed.complete();
  }

  public saveReview(): void {
    let eventJson: EventDetailsDto;
    if ((this.eventForm.dirty || !this.eventForm.invalid) && this.eventData) {
      if (this.eventDetails.length > 1) {
        const arrEventDetails: EventDetails[] = [];
        this.eventDetails.forEach(event => {
          const objEventDetail: EventDetails = {
            ...event,
            isReviewed: true,
            flag: this.eventData.flag,
            category: this.eventData.category,
            reviewComment: this.eventData.reviewComment,
          };
          arrEventDetails.push(objEventDetail);
        });
        eventJson = { eventDetails: arrEventDetails };
      } else {
        this.eventData.isReviewed = true;
        eventJson = { eventDetails: [this.eventData] };
      }

      this.cameraProfileService
        .saveEventReview(eventJson)
        .pipe(
          tap(_response => {
            this.cameraProfileService.getEventData();
            this.messageService.add({ severity: SlbSeverity.Success, summary: EventReviewConstants.successMessage });
            this.dialogRef.close(true);
          }),
          catchError(() => {
            this.messageService.add({ severity: SlbSeverity.Error, summary: EventReviewConstants.errorMessage });

            return of<string>('');
          }),
          takeUntil(this.destroyed)
        )
        .subscribe();
    }
  }
}
