import { Component, ViewChild, ElementRef, AfterViewInit, OnInit, OnDestroy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { tap, takeUntil, finalize, retry, expand, catchError, delay } from 'rxjs/operators';
import { EMPTY, Subject, Subscription, fromEvent, of } from 'rxjs';
import { ZoneService } from '../../shared/services/zone.service';
import { RequestId, ZoneConfiguration, ZoneConfigurationDto, ZoneConfigurationResponse } from '../../shared/models/zone-configuration';
import { MediaData } from '../../shared/models/eventDetails';
import { ZoneConfigurationConstants } from '../../shared/constants/zone-config.constant';
import { NgModel } from '@angular/forms';

@Component({
  selector: 'app-zone-configuration',
  templateUrl: './zone-configuration.component.html',
  styleUrls: ['./zone-configuration.component.scss'],
})
export class ZoneConfigurationComponent implements AfterViewInit, OnInit, OnDestroy {
  @ViewChild('canvas', { static: false }) canvas: ElementRef<HTMLCanvasElement>;
  @ViewChild('zoneNameField') zoneNameField: NgModel;

  public mediaUrl: SafeUrl;
  public selectedMediaFormat: string;
  public zoneName: string;
  public zoneConfiguration!: ZoneConfiguration;
  public isSaved: boolean;
  public isZoneAdded = false;
  public deviceWidth = 800;
  public deviceHeight = 450;
  public controllerId: string;
  public gatewayId: string;
  public image: CanvasImageSource;
  public isLoading: boolean;
  public isZoneNameValid = true;
  public isImageRequested = false;
  public errorMessage: string;
  public sessionToken: string;
  public isZonechange: boolean;
  public isSaveLoading: boolean;
  public isIntersecting = false;
  public message: string;
  public inactiveGateway: boolean;
  public inactiveCamera: boolean;

  private apiError: boolean;
  private ctx!: CanvasRenderingContext2D;
  private destroyed = new Subject();
  private destroyApi = new Subject();
  private isFileSelected = false;
  private pointFlowSubscription!: Subscription;
  private readonly noOfPoints = 20;
  private cameraStatus: string;

  constructor(
    private _DomSanitizationService: DomSanitizer,
    public dialogRef: MatDialogRef<ZoneConfigurationComponent>,
    private zoneService: ZoneService,
    @Inject(MAT_DIALOG_DATA) public data: { cameraStatus: string }
  ) {
    this.cameraStatus = data?.cameraStatus;
  }

  public ngOnInit(): void {
    this.inactiveCamera = false;
    this.inactiveGateway = false;
    const gatewayStatus = sessionStorage.getItem('gatewayStatus');
    this.errorMessage = '';
    if (gatewayStatus) {
      this.inactiveGateway = gatewayStatus !== '0';
      if (this.inactiveGateway) {
        this.errorMessage = ZoneConfigurationConstants.GATEWAYDISABLED;
      } else {
        this.inactiveCamera = this.cameraStatus !== '0';
        this.errorMessage = ZoneConfigurationConstants.CAMERADISABLED;
      }
    }
    this.isZonechange = false;
    this.isSaveLoading = false;
    this.apiError = false;
    this.sessionToken = sessionStorage.getItem('access_token') ?? '';
    const locationArr = location.href.split('/');
    this.controllerId = locationArr[locationArr.length - 2];
    this.gatewayId = locationArr[locationArr.length - 3];
  }

  public ngAfterViewInit(): void {
    this.ctx = (this.canvas?.nativeElement as HTMLCanvasElement).getContext('2d', { willReadFrequently: true }) as CanvasRenderingContext2D;
    if (!this.inactiveCamera && !this.inactiveGateway) {
      this.resetErrorMessage();
    }
    this.zoneService
      .getZoneConfig(this.controllerId)
      .pipe(
        tap((zoneData: ZoneConfigurationDto) => {
          if (zoneData && zoneData.detail !== ZoneConfigurationConstants.DETAILERROR) {
            const zoneDetails: ZoneConfiguration = zoneData.zoneConfiguration;
            this.zoneConfiguration = zoneDetails;
            this.zoneName = zoneDetails.zoneName;
            this.setFile(zoneDetails.zoneConfigFileUrl + '/?token=' + this.sessionToken);
            this.isSaved = true;
          } else {
            if (!this.inactiveCamera && !this.inactiveGateway) {
              this.apiError = true;
              this.errorMessage = ZoneConfigurationConstants.ZONEGETERROR;
            }
            this.zoneConfiguration = {
              controllerId: '',
              zoneName: '',
              zoneConfigFileUrl: '',
              timestamp: '',
              xyCoordinates: [],
            };
          }
        }),
        catchError(() => {
          this.apiError = true;
          this.errorMessage = ZoneConfigurationConstants.ZONEGETERROR;
          this.mediaUrl = '';

          return of<string>('');
        }),
        takeUntil(this.destroyApi)
      )
      .subscribe();
  }

  public redrawOnCanvas(): void {
    if (this.zoneName.length && this.zoneConfiguration.xyCoordinates.length > 0) {
      this.zoneConfiguration.controllerId = this.controllerId;
      this.zoneConfiguration.zoneName = this.zoneName;
      this.zoneConfiguration.timestamp = new Date().toISOString();
    }
  }

  public saveZoneConfiguration(): void {
    this.isSaveLoading = true;
    this.resetErrorMessage();
    if (this.zoneConfiguration?.xyCoordinates?.length > 0) {
      const zoneConfiguration: ZoneConfigurationDto = {
        zoneConfiguration: this.zoneConfiguration,
      };

      this.zoneService
        .saveZoneConfig(zoneConfiguration)
        .pipe(
          tap((zoneData: ZoneConfigurationResponse) => {
            this.isSaveLoading = false;
            this.dialogRef.close({ data: zoneData });
          }),
          catchError(() => {
            this.isSaveLoading = false;
            this.apiError = true;
            this.errorMessage = ZoneConfigurationConstants.ZONESAVEERROR;

            return of<ZoneConfiguration>({} as ZoneConfiguration);
          }),
          takeUntil(this.destroyApi)
        )
        .subscribe();
    }
  }

  public closeZoneConfiguration(): void {
    this.dialogRef.close();
  }

  public resetZone(): void {
    this.isSaved = false;
    this.isZoneAdded = false;
    this.isImageRequested = false;
    this.isZoneNameValid = true;
    this.isIntersecting = false;
    this.message = '';
    if (!this.pointFlowSubscription || this.pointFlowSubscription?.closed) {
      this.drawOnCanvas();
    }
  }

  public getImages(): void {
    this.isLoading = true;
    this.isZoneAdded = false;
    this.resetErrorMessage();
    this.zoneService
      .getRequestImage(this.gatewayId, this.controllerId)
      .pipe(
        tap((data: RequestId) => {
          if (data && data?.mediaRequestId) {
            this.processMediaData(data.mediaRequestId);
            this.isImageRequested = true;
          }
        }),
        catchError(() => {
          if (this.zoneConfiguration?.xyCoordinates?.length > 0) {
            this.isZoneAdded = true;
          }
          this.isLoading = false;
          this.isImageRequested = true;
          this.apiError = true;
          this.errorMessage = ZoneConfigurationConstants.ZONEGETIMAGEERROR;

          return of<string>('');
        }),
        takeUntil(this.destroyApi)
      )
      .subscribe();
  }

  public removeRedzone(): void {
    this.zoneConfiguration.xyCoordinates = [];
    this.zoneConfiguration.zoneName = '';
    this.zoneNameField.reset();
    this.zoneName = '';
    this.resetZone();
    this.setFile(this.zoneConfiguration.zoneConfigFileUrl + '/?token=' + this.sessionToken);
  }

  public checkError(): boolean {
    const reqImage = !this.isLoading && this.isImageRequested && this.zoneConfiguration && !this.zoneConfiguration?.zoneConfigFileUrl;

    return (
      reqImage ||
      this.inactiveGateway ||
      this.inactiveCamera ||
      this.apiError ||
      this.isIntersecting ||
      this.zoneConfiguration?.xyCoordinates?.length === 20
    );
  }

  ngOnDestroy(): void {
    this.destroyed.next(true);
    this.destroyed.complete();
    this.destroyApi.next(true);
    this.destroyApi.complete();
    this.pointFlowSubscription?.unsubscribe();
  }

  public checkZoneNameValid(): void {
    if (this.zoneName) {
      const exactMatch = new RegExp('^[a-zA-Z0-9- ]*$');
      const matchResult = this.zoneName.match(exactMatch);
      this.isZonechange = true;
      this.isZoneNameValid = matchResult ? true : false;
    }
  }

  private setFile(file: string): void {
    if (file) {
      this.isFileSelected = true;
      if (file.indexOf('jpg') > -1 || file.indexOf('jpeg') > -1 || file.indexOf('png') > -1) {
        this.selectedMediaFormat = 'image';
        this.mediaUrl = this._DomSanitizationService.bypassSecurityTrustUrl(file);
        const img = new Image();
        img.src = file;
        img.onload = (): void => {
          this.image = img;
          this.setCoordinates(img);
        };
      }
    }
  }

  private processMediaData(requestid: string): void {
    this.resetErrorMessage();
    const getMedia = this.zoneService.getZoneImage(requestid);
    getMedia
      .pipe(
        expand((result: MediaData) => (!result?.media?.fileUrl ? getMedia : EMPTY).pipe(delay(10000))),
        tap((mediaData: MediaData) => {
          if (mediaData && mediaData?.media?.fileUrl) {
            this.resetZone();
            this.mediaUrl = '';
            this.isLoading = false;
            this.setFile(mediaData?.media?.fileUrl + '/?token=' + this.sessionToken);
            this.zoneConfiguration.zoneConfigFileUrl = mediaData.media.fileUrl;
            if (this.zoneConfiguration.xyCoordinates?.length > 0) {
              this.isZoneAdded = true;
            }
            this.checkZoneNameValid();
          }
        }),
        retry({ count: 7, delay: 10000 }),
        catchError((err: any) => {
          if (err !== '') {
            this.isLoading = false;
            this.isZoneAdded = true;
            this.apiError = true;
            this.isImageRequested = true;
            this.errorMessage = ZoneConfigurationConstants.ZONEGETMEDIAERROR;
          }

          return of<string>('');
        }),
        takeUntil(this.destroyApi)
      )
      .subscribe();
  }

  private setCoordinates(img: CanvasImageSource): void {
    this.ctx.strokeStyle = '#FF0000';
    this.ctx.font = '20px Arial';
    this.ctx.lineWidth = 2;
    this.ctx.drawImage(img, 0, 0, this.ctx.canvas.width, this.ctx.canvas.height);
    if (this.zoneConfiguration?.xyCoordinates?.length > 0) {
      this.isZoneAdded = true;
      this.ctx.beginPath();
      this.ctx.moveTo(this.zoneConfiguration.xyCoordinates?.[0].x, this.zoneConfiguration.xyCoordinates?.[0].y);
      for (let i = 0; i < this.zoneConfiguration.xyCoordinates.length - 1; i++) {
        this.ctx.lineTo(this.zoneConfiguration.xyCoordinates?.[i + 1]?.x, this.zoneConfiguration.xyCoordinates?.[i + 1]?.y);
        this.ctx.stroke();
        this.ctx.globalAlpha = 1;
      }
      this.highlightZone();
      this.ctx.beginPath();
      this.ctx.moveTo(this.zoneConfiguration.xyCoordinates?.[0]?.x, this.zoneConfiguration.xyCoordinates?.[0]?.y);
      this.ctx.lineTo(
        this.zoneConfiguration.xyCoordinates[this.zoneConfiguration.xyCoordinates?.length - 1]?.x,
        this.zoneConfiguration.xyCoordinates[this.zoneConfiguration.xyCoordinates?.length - 1]?.y
      );

      this.ctx.stroke();
      this.ctx.closePath();

      this.isFileSelected = false;
    }
  }

  private drawOnCanvas(): void {
    const clickStream = fromEvent(this.canvas.nativeElement, 'click');

    this.pointFlowSubscription = clickStream
      .pipe(
        tap((clickStreamEvent: Event) => {
          if (!Number(this.noOfPoints) || this.isIntersecting || !this.isFileSelected) {
            return;
          }

          const mouseClick = clickStreamEvent as MouseEvent;
          if (!this.zoneConfiguration?.xyCoordinates.length && this.zoneConfiguration.zoneConfigFileUrl.length) {
            this.ctx.beginPath();
            this.ctx.strokeStyle = 'red';
            this.ctx.lineWidth = 2;
            this.ctx.lineJoin = 'round';
            this.ctx.moveTo(mouseClick.offsetX, mouseClick.offsetY);
            // only for the first starting point.
            this.zoneConfiguration.xyCoordinates.push({
              x: mouseClick.offsetX,
              y: mouseClick.offsetY,
            });
          } else if (this.zoneConfiguration?.xyCoordinates.length < this.noOfPoints) {
            this.ctx.lineTo(mouseClick.offsetX, mouseClick.offsetY);
            this.ctx.stroke();
            this.zoneConfiguration.xyCoordinates.push({
              x: mouseClick.offsetX,
              y: mouseClick.offsetY,
            });
            // check for intersections
            if (this.zoneConfiguration?.xyCoordinates.length >= 4) {
              const max = this.zoneConfiguration?.xyCoordinates.length - 1;
              this.checkForIntersection(max);
            }
            // connect the last points with the first point if user have reached limit of 20 points and still not intersceting.
            if (this.zoneConfiguration?.xyCoordinates.length === this.noOfPoints && !this.isIntersecting) {
              this.ctx.lineTo(this.zoneConfiguration?.xyCoordinates[0].x, this.zoneConfiguration?.xyCoordinates[0].y);
              this.ctx.stroke();
              this.ctx.closePath();
              this.highlightZone();
              this.errorMessage = ZoneConfigurationConstants.ZONE_POINT_LIMIT_EXCEED_MESSAGE;
              this.isZoneAdded = true;
            }
          }
        }),
        takeUntil(this.destroyed),
        finalize(() => {
          this.ctx.closePath();
        })
      )
      .subscribe();
  }

  private checkForIntersection(max: number): void {
    // length = 4 - 0,1,2,3
    // length = 5 - 0,1,3,4 | 1,2,3,4 |
    // length = 6 - 0,1,4,5 | 1,2,4,5 | 2,3,4,5
    for (let i = 0; i < max && i + 1 !== max - 1; i++) {
      const p0 = this.zoneConfiguration?.xyCoordinates[i];
      const p1 = this.zoneConfiguration?.xyCoordinates[i + 1];
      const p2 = this.zoneConfiguration?.xyCoordinates[max - 1];
      const p3 = this.zoneConfiguration?.xyCoordinates[max];
      const result = this.checkIfLineIntersecting(p0.x, p0.y, p1.x, p1.y, p2.x, p2.y, p3.x, p3.y);
      if (result) {
        this.isIntersecting = true;
        this.errorMessage = ZoneConfigurationConstants.ZONE_DEFINATION_INTERSECTION_MESSAGE;
        this.ctx.closePath();
        this.highlightZone();
        this.isZoneAdded = true;
        break;
      }
    }
  }

  private checkIfLineIntersecting(
    p0_x: number,
    p0_y: number,
    p1_x: number,
    p1_y: number,
    p2_x: number,
    p2_y: number,
    p3_x: number,
    p3_y: number
  ): number {
    const s1X = p1_x - p0_x;
    const s1Y = p1_y - p0_y;
    const s2X = p3_x - p2_x;
    const s2Y = p3_y - p2_y;

    const s = (-s1Y * (p0_x - p2_x) + s1X * (p0_y - p2_y)) / (-s2X * s1Y + s1X * s2Y);
    const t = (s2X * (p0_y - p2_y) - s2Y * (p0_x - p2_x)) / (-s2X * s1Y + s1X * s2Y);

    if (s >= 0 && s <= 1 && t >= 0 && t <= 1) {
      return 1;
    }

    return 0; // No collision
  }

  private resetErrorMessage(): void {
    this.apiError = false;
    this.errorMessage = '';
  }

  private highlightZone(): void {
    this.ctx.strokeStyle = '#FF0000';
    this.ctx.font = '20px Arial';
    this.ctx.fillStyle = '#FF0000';
    this.ctx.globalAlpha = 0.1;
    this.ctx.fill();
    this.ctx.globalAlpha = 1;
  }
}
