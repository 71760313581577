export class ZoneConfigurationConstants {
  public static ZONEGETMEDIAERROR = 'Error while receiveing media details';
  public static ZONEGETIMAGEERROR = 'Error while receiveing image details';
  public static ZONEGETVIDEOERROR = 'Error while receiveing video details';
  public static ZONEGETERROR = 'Error while receiveing zone details';
  public static ZONESAVEERROR = 'Error while saving zone details';
  public static DETAILERROR = 'No Record Found';
  public static ZONEREQUESTERROR = 'No latest image available';
  public static GATEWAYDISABLED = 'Gateway is disconnected.';
  public static CAMERADISABLED = 'Camera is disconnected.';
  public static ZONEFAILEDERROR = 'Please try again.';
  public static CONFIRMMESSAGE = 'Your changes will be discarded, do you want to continue?';
  public static ZONE_POINT_LIMIT_EXCEED_MESSAGE = 'You have reached the defined limit of number of points.';
  public static ZONE_DEFINATION_INTERSECTION_MESSAGE = 'Lines are intersecting, you can not draw further.';
}
