<div class="event-review-title">
  <span mat-dialog-title class="event-review-dialog-title">Review</span>
  <slb-modal-close-button id="event_review_close_btn" buttonType="Icon" [dialogRef]="dialogRef"></slb-modal-close-button>
</div>
<div mat-dialog-content class="event-review-dialog-content">
  <form [formGroup]="eventForm">
    <div *ngIf="eventData.eventId !== 0">
      <mat-form-field slbFormField>
        <mat-label>Event ID</mat-label>
        <input
          matInput
          required
          formControlName="eventId"
          [(ngModel)]="eventData.eventId"
          [readonly]="true"
          id="agora_vi_event_review_eventId_text" />
      </mat-form-field>
    </div>

    <div>
      <mat-form-field class="category" appearance="outline" floatLabel="always">
        <mat-label>Category</mat-label>
        <mat-select
          formControlName="category"
          required
          hideSingleSelectionIndicator="true"
          id="agora_vi_event_review_category_dropdown"
          [(ngModel)]="eventData.category"
          placeholder="Select">
          <mat-option *ngFor="let category of categories; let i = index" [value]="i">
            {{ category }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="top" appearance="outline" floatLabel="always">
        <mat-label> Flag </mat-label>
        <mat-select
          formControlName="flag"
          required
          id="agora_vi_event_review_flags_dropdown"
          [(ngModel)]="eventData.flag"
          hideSingleSelectionIndicator="true"
          placeholder="Select">
          <mat-option *ngFor="let flag of flagsData; let i = index" [value]="i">{{ flag }} </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="comments">
      <mat-form-field slbFormField>
        <mat-label>Remark</mat-label>
        <textarea
          matInput
          required
          class="comment"
          formControlName="comment"
          type="textarea"
          id="agora_vi_event_review_comment_textarea"
          maxlength="200"
          hideSingleSelectionIndicator="true"
          name="comment"
          [(ngModel)]="eventData.reviewComment"></textarea>
      </mat-form-field>
    </div>
  </form>
  <div class="actions">
    <button mat-dialog-close type="button" slb-secondary-button id="agora_vi_event_review_cancel_btn">Cancel</button>
    <button
      slb-button
      (click)="saveReview()"
      [disabled]="!eventForm.dirty || eventForm.invalid || !eventData.reviewComment.trim().length"
      id="agora_vi_event_review_save_btn">
      Save
    </button>
  </div>
</div>
