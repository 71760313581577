<div>
  <p *ngIf="dataSource && dataSource.data && dataSource.data.length === 0; else showTable" class="no-data">No data available</p>
  <ng-template #showTable>
    <table
      mat-table
      *ngIf="dataSource && dataSource.data && dataSource.data.length > 0"
      matSort
      [dataSource]="dataSource"
      class="mat-elevation-z8"
      matSortActive="timestamp"
      matSortDirection="desc">
      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            (change)="$event ? toggleAllRows() : null; checkboxChange()"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row; let i = index" id="{{ 'vi_camera_profile_alerts_table' + '_row_selection_' + (i + 1) }}">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(row) : null; checkboxChange()"
            [checked]="selection.isSelected(row)">
          </mat-checkbox>
        </td>
      </ng-container>

      <!-- Timestamp Column -->
      <ng-container matColumnDef="timestamp">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Event time</th>
        <td mat-cell *matCellDef="let element; let i = index" id="{{ 'vi_camera_profile_alerts_table' + '_event_time_' + (i + 1) }}">
          {{ element.timestamp | date : 'MM/dd/yyyy HH:mm:ss a' : currentZone }}
        </td>
      </ng-container>

      <!-- Event Column -->
      <ng-container matColumnDef="peopleInsideRedZone">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Reason</th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          id="{{ 'vi_camera_profile_alerts_table' + '_people_inside_redzone' + (i + 1) }}">
          <p>
            <span
              *ngIf="
                element?.rigState === 'Red zone static' || (element?.peopleInsideRedZone === 0 && element?.ppeViolations === 0);
                else rigData
              ">
              <span class="bg-setting people-redzone"></span>
              <strong>Red zone static</strong>
            </span>
            <ng-template #rigData>
              <p class="camera-info">
                <span
                  class="bg-setting"
                  ngClass="{{ element && element.peopleInsideRedZone > 0 ? 'people-redzone' : 'ppe-violations' }}"></span>
                <span>{{ element && element.peopleInsideRedZone > 0 ? 'Rig floor Red zone state: ' : 'Missing: ' }}</span>
                <span class="value" matTooltip="{{ element.rigState }}">{{ element.rigState }}</span>
              </p>
            </ng-template>
          </p>
        </td>
      </ng-container>

      <ng-container matColumnDef="isReviewed">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Reviewed</th>
        <td mat-cell *matCellDef="let element; let i = index" id="{{ 'vi_camera_profile_alerts_table' + '_reviewed' + (i + 1) }}">
          <span *ngIf="element.isReviewed; else notReviewed"><mat-icon svgIcon="check" class="check-icon">check</mat-icon></span>
          <ng-template #notReviewed><mat-icon svgIcon="close" class="close-icon">close</mat-icon></ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="flag">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Flag</th>
        <td mat-cell *matCellDef="let element; let i = index" id="{{ 'vi_camera_profile_alerts_table' + '_flag' + (i + 1) }}">
          {{
            element.flag === 0
              ? 'Near miss'
              : element.flag === 1
              ? 'Minor incident'
              : element.flag === 2
              ? 'Lost time injuries'
              : element.flag === 3
              ? 'Fatality'
              : element.flag
          }}
        </td>
      </ng-container>

      <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Category</th>
        <td mat-cell *matCellDef="let element; let i = index" id="{{ 'vi_camera_profile_alerts_table' + '_flag' + (i + 1) }}">
          {{
            element.category === 0
              ? 'Yet to be reviewed'
              : element.category === 1
              ? 'Reviewed, invalid'
              : element.category === 2
              ? 'Reviewed, as system detected'
              : element.category === 3
              ? 'Reviewed, human behavior'
              : element.category === 4
              ? 'Exclude'
              : element.category
          }}
        </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef>Áction</th>
        <td mat-cell *matCellDef="let element; let i = index" id="{{ 'vi_camera_profile_alerts_table' + '_image' + (i + 1) }}">
          <mat-icon
            svgIcon="preview"
            class="image-icon cursor-pointer"
            (click)="onImageClick(element, i); element.isClicked = true"></mat-icon>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" [ngClass]="{ highlight: row.isClicked }"></tr>
    </table>
  </ng-template>
</div>
<mat-paginator
  id="vi_camera_profile_alerts_table_pagination"
  [pageSizeOptions]="[10, 20, 30]"
  [length]="totalNoRecords"
  #paginator
  showLabel=" false"
  showFirstLastButtons
  (page)="pageEvent($event)"
  class="camera-profile-table-pagination">
</mat-paginator>
<mat-drawer #drawer class="example-sidenav" position="end" [opened]="isOpen">
  <app-latest-camera-popover
    *ngIf="popOverCameraDetails"
    [eventIndex]="selectedPopOverIndex"
    [hasNext]="hasNext"
    [hasPrevious]="hasPrevious"
    [cameraDetails]="popOverCameraDetails"
    (nextPrevEvent)="onEventClick($event)"
    (closeSideInfo)="drawer.close(); popOverCameraDetails = null; isOpen = false"></app-latest-camera-popover>
</mat-drawer>
