import { NavItem } from '@slb-dls/angular-material/shared';

export const secondaryNavItems: NavItem[] = [
  {
    label: 'Dashboard',
    routerLink: 'home',
    active: true,
  },
  {
    label: 'Camera',
    routerLink: 'camera',
    active: false,
  },
];
