import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RadioButtonValue } from '@slb-dls/angular-material/radio-button-group';
import { ZoneSettingsConstants } from '../../shared/constants/zone-setting-constants';
import { ZoneConfig, ZoneSetting, ZoneSettingOptions } from '../../shared/models/zoneSetting';
import { ZoneService } from '../../shared/services/zone.service';
import { EMPTY, Subject, catchError, delay, expand, of, takeUntil, tap } from 'rxjs';
import { MessageService, SlbSeverity } from '@slb-dls/angular-material/notification';
import { LoginService } from '@agora/agora-ui-library';
import { CameraProfileService } from '../../shared/services/camera-profile.service';

@Component({
  selector: 'app-zone-setting',
  templateUrl: './zone-setting.component.html',
  styleUrls: ['./zone-setting.component.scss'],
})
export class ZoneSettingComponent implements OnInit, OnDestroy {
  public updatedBy: string;
  public updatedOn: Date | null;
  public redZoneTypes: RadioButtonValue[] = ZoneSettingsConstants.REDZONETYPE_RADIO_OPTIONS;
  public zoneSettings: ZoneSettingOptions[] = [];
  public currentRedZone: number;
  public zoneId: number;
  public userName: string;
  public userEmail: string;
  public controllerId: string;
  public groupdId: string;
  public existingZoneSetting: ZoneSetting;
  public showLoader = false;
  public status: string;
  public isValid: boolean[] = [];
  public inactiveGateway: boolean;
  public inactiveCamera: boolean;
  private destroyed = new Subject();
  private cameraStatus: string;

  constructor(
    public dialogRef: MatDialogRef<ZoneSettingComponent>,
    public zoneService: ZoneService,
    private messageService: MessageService,
    private loginService: LoginService,
    private cameraService: CameraProfileService,
    @Inject(MAT_DIALOG_DATA) public data: { cameraStatus: string }
  ) {
    this.cameraStatus = data?.cameraStatus;
    this.cameraService.selectedZoneId$
      .pipe(
        tap((params: ZoneConfig) => {
          if (params) {
            this.zoneId = params.zoneId;
            this.controllerId = params.deviceId;
            this.currentRedZone = params.redZoneType;
            this.groupdId = params.groupdId;
            if (this.groupdId) {
              this.zoneSettings =
                this.groupdId !== 'bsp'
                  ? JSON.parse(JSON.stringify(ZoneSettingsConstants.NONBSPTAGSOPTIONS))
                  : JSON.parse(JSON.stringify(ZoneSettingsConstants.BSPTAGSOPTIONS));
            }
          }
        }),
        takeUntil(this.destroyed)
      )
      .subscribe();
  }

  public ngOnInit(): void {
    this.inactiveCamera = false;
    this.inactiveGateway = false;
    const gatewayStatus = sessionStorage.getItem('gatewayStatus');
    if (gatewayStatus) {
      this.inactiveGateway = gatewayStatus !== '0' ? true : false;
      if (!this.inactiveGateway) {
        this.inactiveCamera = this.cameraStatus !== '0' ? true : false;
      }
    }
    this.loginService.isUserLoggedIn$
      .pipe(
        tap(() => {
          this.userEmail = this.loginService.getUserInfo()?.email ?? '';
          this.userName = this.loginService.getUserInfo()?.name ?? '';
        })
      )
      .subscribe();
    this.getZoneSettings();
  }

  public getZoneSettings(): void {
    if (this.zoneId !== 0) {
      this.zoneService
        .getZoneSetting(this.zoneId)
        .pipe(
          catchError(() => {
            return of<ZoneSetting>({} as ZoneSetting);
          }),
          tap((data: ZoneSetting) => {
            if (data) {
              this.existingZoneSetting = data;
              this.updatedBy = data.UpdateBy;
              this.updatedOn = data.UpdateTime ? new Date(data.UpdateTime) : null;
              this.currentRedZone = data.ZoneTypeId;
              this.updateZoneSetting(data.ZoneSettingData);
              this.status = data.CommandStatus ? data.CommandStatus : '';
            }
          }),
          takeUntil(this.destroyed)
        )
        .subscribe();
    }
  }

  public updateZoneSetting(response: any): void {
    if (response) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      for (const key of Object.keys(response)) {
        const index = this.zoneSettings.findIndex(x => x.tagname === key);
        if (index !== -1) {
          if (response[key] && response[key] !== null) {
            this.zoneSettings[index].value = response[key];
          }
        }
      }
    }
  }

  public close(): void {
    this.dialogRef.close();
  }

  public updateNewSettings(tags: any): any {
    this.zoneSettings.map(zone => {
      if (tags[zone.tagname]) {
        tags[zone.tagname] = zone.value;
      }
    });

    return tags;
  }

  public saveZoneSettings(): void {
    this.showLoader = true;
    const zoneSettingJson = this.groupdId === 'bsp' ? ZoneSettingsConstants.bspTags : ZoneSettingsConstants.NonBSPTags;

    this.zoneSettings.forEach((ele, index) => {
      const key = ele['tagname'];
      if (zoneSettingJson.hasOwnProperty(ele['tagname'])) {
        this.checkZoneSettingValid(ele['value'], index);
        zoneSettingJson[key] = ele['value'];
      }
    });

    const zoneSettingRequest = {
      ID: this.zoneId,
      ControllerID: this.controllerId,
      ZoneTypeId: this.currentRedZone,
      ZoneSettingData: zoneSettingJson,
      UpdateBy: this.userEmail,
      GroupID: this.groupdId,
    } as ZoneSetting;

    if (this.zoneId !== 0) {
      zoneSettingRequest.EquipmentID = this.existingZoneSetting.EquipmentID;
    }
    if (this.isValid) {
      this.zoneService
        .saveZoneSetting(zoneSettingRequest, this.zoneId)
        .pipe(
          tap(_response => {
            if (_response) {
              if (_response.response) {
                this.cameraService.setZoneId({
                  deviceId: this.controllerId,
                  groupdId: this.groupdId,
                  zoneId: _response.response.ID,
                  redZoneType: _response.response.ZoneTypeId,
                } as ZoneConfig);
                this.showLoader = false;
              }
            }
            this.dialogRef.close(true);
            this.getCommandStaus(_response.response.CommandId as string);
          }),
          catchError(err => {
            if (err) {
              this.messageService.add({ severity: SlbSeverity.Error, summary: ZoneSettingsConstants.zone_errorMessage });
              this.showLoader = false;
            }

            return of<string>('');
          }),
          takeUntil(this.destroyed)
        )
        .subscribe();
    }
  }

  ngOnDestroy(): void {
    this.destroyed.next(true);
  }

  public checkZoneSettingValid(event: number, index: number): void {
    if (event === null) {
      this.isValid[index] = false;
    } else {
      const pattern = new RegExp('^-?[0-9]*(?:.[0-9]{0,5})?$');
      const result = pattern.test(event.toString());
      this.isValid[index] = result;
    }
  }

  public allValid = (arr: boolean[]): boolean => arr.every(val => val === true);

  private getCommandStaus(commandId: string): void {
    const getCommandStatus = this.zoneService.getCommandStatus(this.zoneId, commandId, this.controllerId, this.groupdId);
    getCommandStatus
      .pipe(
        expand((result: string) =>
          (!(
            result?.toLowerCase().includes('acknowledged') ||
            result?.toLowerCase().includes('timeout') ||
            result?.toLowerCase().includes('error')
          )
            ? getCommandStatus
            : EMPTY
          ).pipe(delay(5000))
        ),
        catchError(err => {
          if (err) {
            this.messageService.add({ severity: SlbSeverity.Error, summary: ZoneSettingsConstants.zone_errorMessage });
          }

          return '';
        })
      )
      .subscribe();
  }
}
