import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MessageService, SlbSeverity } from '@slb-dls/angular-material/notification';
import { CustomCalendarComponent } from '../custom-calendar/custom-calendar.component';
import { EventData, EventDataIteration, EventDetails, Media } from '../../shared/models/eventDetails';
import { CameraProfileService } from '../../shared/services/camera-profile.service';
import { Subject, catchError, filter, of, switchMap, takeUntil, tap } from 'rxjs';
import { CameraStatus } from '../../shared/constants/enum';
import {
  ALL,
  EVENTERROR,
  EVENT_CSV_DOWNLOAD_HEADING,
  EVENT_FILTER,
  FILE_DOWNLOAD_ERROR,
  PPE,
  REDZONE,
} from '../../shared/constants/camera-profile-constant';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { TimeZoneService } from '../../shared/services/time-zone.service';
import { TimeZone } from '../../shared/models/dashabordAlertData';
import { PopUpService } from '../../shared/services/pop-up.service';
import { DashboardService } from '../../shared/services/dashboard.service';
import { DashboardConstants } from '../../shared/constants/dashboard-constants';
import { CameraConstants } from 'src/app/shared/constants/camera-constant';

@Component({
  selector: 'app-alerts-container',
  templateUrl: './alerts-container.component.html',
  styleUrls: ['./alerts-container.component.scss'],
})
export class AlertsContainerComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() cameraStatus: CameraStatus;
  public violationData: EventDetails[];
  public viewMode = 'grid';
  public selectedFiles: EventDetails[];
  public checkAll: boolean;
  public violationDataList: EventDetails[];
  public dayMode: string;
  public startDate: Date;
  public endDate: Date;
  public controllerId: string;
  public gatewayId: string;
  public isLoading: boolean;
  public currentZone = '';
  public refreshedTimezone = '';
  public totalRecords = 0;
  public reviewFilterList = EVENT_FILTER;
  public reviewCategory = 'All';
  public paginatedData: PageEvent;
  public isDownloadStarted = false;
  public strikeRedzone = false;
  public strikePPE = false;
  public noEventData: boolean;

  private SECONDS_IN_HOUR = 3600 * 1000;
  private HOUR = 1;
  private DAY = 24;
  private WEEK = 168;
  private cameraName: string;
  private destroyed = new Subject();
  private previousDaymode: string;
  private pageNumber: number;
  private recordsPerPage: number;
  private eventTime: Date;
  private fromDashboard = false;
  private event: string;

  constructor(
    private dialog: MatDialog,
    private cameraProfileService: CameraProfileService,
    private dashboardService: DashboardService,
    private timeZoneService: TimeZoneService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private popupService: PopUpService
  ) {
    this.violationDataList = this.violationData;
    this.route.params
      .pipe(
        tap(params => {
          if (params) {
            this.gatewayId = params.gateway;
            this.controllerId = params.controllerId;
            this.cameraName = params.cameraName;
          }
        }),
        switchMap(() =>
          this.timeZoneService.timeZoneDetails$.pipe(
            filter((zone: TimeZone) => !!Object.keys(zone).length),
            tap((_zone: TimeZone) => (this.currentZone = this.timeZoneService.getTimeZone()))
          )
        ),
        switchMap(() =>
          this.cameraProfileService.selectedEventTime$.pipe(
            tap((eventTime: string) => {
              if (eventTime) {
                this.fromDashboard = true;
                this.eventTime = new Date(eventTime);
              } else if (sessionStorage.getItem('selectedCameraEventDate')) {
                const sessionEventTime = sessionStorage.getItem('selectedCameraEventDate')?.toString();
                if (sessionEventTime) {
                  this.fromDashboard = true;
                  this.eventTime = new Date(sessionEventTime);
                }
              }
            })
          )
        ),
        takeUntil(this.destroyed)
      )
      .subscribe();
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.selectedFiles = [];
    this.noEventData = false;
    this.event = ALL;
    this.pageNumber = DashboardConstants.PAGE_NUMBER;
    this.recordsPerPage = DashboardConstants.RECORDS_PER_PAGE;
    if (!this.fromDashboard) {
      this.dayMode = 'day';
      this.previousDaymode = 'day';
      this.startDate = new Date();
      this.endDate = new Date();
    } else {
      this.checkTimeRange();
    }
  }

  ngAfterViewInit(): void {
    if (!this.fromDashboard) {
      this.generateDate();
    }
  }

  public switchView(event: { value: string }): void {
    this.viewMode = event.value;
  }

  public downloadSelectedFile(): void {
    const downloadUrls: number[] = [];
    if (this.selectedFiles && this.selectedFiles.length > 0) {
      this.selectedFiles.forEach(file => {
        const medias = file.media.filter((media: Media) => media.fileUrl);
        if (medias.length) {
          downloadUrls.push(file.eventId);
        }
      });
      if (downloadUrls && downloadUrls.length > 0) {
        this.downloadFiles(downloadUrls);
      } else {
        this.messageService.add({ severity: SlbSeverity.Error, summary: FILE_DOWNLOAD_ERROR });
      }
    }
  }

  public async openReviewPopup(): Promise<void> {
    if (this.selectedFiles && this.selectedFiles.length > 0) {
      await this.popupService.openEditReviewConfiguration(this.selectedFiles);
    }
  }

  public getSelectedFiles(value: EventDetails[]): void {
    this.selectedFiles = value;
    if (this.violationDataList.length > this.selectedFiles.length) {
      this.checkAll = false;
    } else if (this.violationDataList.length === this.selectedFiles.length) {
      this.checkAll = true;
    }
    this.updateSelection();
  }

  public checkAllFiles(): void {
    if (this.checkAll) {
      this.selectedFiles = this.violationDataList;
      this.violationDataList.map((data: EventDetails) => (data['isChecked'] = true));
      this.violationDataList = [...this.violationDataList];
    } else {
      this.violationDataList.map((data: EventDetails) => (data['isChecked'] = false));
      this.violationDataList = [...this.violationDataList];
      this.selectedFiles = [];
    }
    this.updateSelection();
  }

  public switchDays(event: { value: string }): void {
    this.previousDaymode = this.dayMode;
    this.dayMode = event.value;
    if (this.dayMode !== 'custom') {
      this.isLoading = true;
      this.selectedFiles = [];
      this.pageNumber = DashboardConstants.PAGE_NUMBER;
      this.recordsPerPage = DashboardConstants.RECORDS_PER_PAGE;
      this.totalRecords = 0;
      this.paginatedData = {
        pageIndex: 0,
        pageSize: DashboardConstants.RECORDS_PER_PAGE,
        length: DashboardConstants.PAGE_NUMBER,
      };
      this.generateDate();
    }
  }

  public customClick(): void {
    const dialogRef = this.dialog.open(CustomCalendarComponent, {
      width: '550px',
      data: {
        startDate: this.startDate,
        endDate: this.endDate,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.type === 'save') {
        this.startDate = result.dates.startDate;
        this.endDate = result.dates.endDate;
        this.isLoading = true;
        this.selectedFiles = [];
        this.pageNumber = DashboardConstants.PAGE_NUMBER;
        this.recordsPerPage = DashboardConstants.RECORDS_PER_PAGE;
        this.totalRecords = 0;
        this.paginatedData = {
          pageIndex: 0,
          pageSize: DashboardConstants.RECORDS_PER_PAGE,
          length: DashboardConstants.PAGE_NUMBER,
        };
        this.getEvent();
        this.previousDaymode = 'custom';
      } else {
        this.dayMode = this.previousDaymode;
      }
    });
  }

  public getPaginatedData(event: PageEvent): void {
    if (this.pageNumber !== event.pageIndex + 1 || this.recordsPerPage !== event.pageSize) {
      this.paginatedData = event;
      this.pageNumber = event.pageIndex + 1;
      this.recordsPerPage = event.pageSize;
      this.selectedFiles = [];
      this.getEvent();
    }
  }

  public getFilteredReviews(): void {
    this.pageNumber = 1;
    this.recordsPerPage = DashboardConstants.RECORDS_PER_PAGE;
    this.totalRecords = 0;
    this.selectedFiles = [];
    this.paginatedData = {
      pageIndex: 0,
      pageSize: DashboardConstants.RECORDS_PER_PAGE,
      length: DashboardConstants.PAGE_NUMBER,
    };
    this.getEvent();
  }

  public assignEvent(): void {
    this.pageNumber = 1;
    if (this.strikeRedzone && this.strikePPE) {
      this.noEventData = true;
      this.violationDataList = [];
      this.event = '';
    } else if (!this.strikeRedzone && this.strikePPE) {
      this.event = REDZONE;
      this.getEvent();
    } else if (this.strikeRedzone && !this.strikePPE) {
      this.event = PPE;
      this.getEvent();
    } else {
      this.event = ALL;
      this.getEvent();
    }
    this.paginatedData.pageIndex = 0;
  }

  public downloadCsvEventData(): void {
    const startTime = this.calculateTimeBasedOnTimezone(this.startDate);
    const endTime = this.calculateTimeBasedOnTimezone(this.endDate);
    const recordsPerPage = 50;
    const pageNumber = 1;
    this.isDownloadStarted = true;
    this.messageService.add({ severity: SlbSeverity.Info, summary: CameraConstants.CSV_DOWNLOAD_WAIT_MSG, sticky: true, closable: false });
    let csvData = '';
    let blob = new Blob();

    this.cameraProfileService
      .getAllEventData(
        this.gatewayId,
        this.controllerId,
        startTime,
        endTime,
        pageNumber,
        recordsPerPage,
        this.reviewCategory.toLowerCase(),
        this.event
      )
      .pipe(
        tap((value: EventDataIteration) => {
          const columnNames = EVENT_CSV_DOWNLOAD_HEADING;

          if (!value?.totalRecords || !columnNames?.length) {
            return;
          }

          const eventData: EventDetails[] = JSON.parse(JSON.stringify(value.data));
          csvData = this.cameraProfileService.convertToCsv(csvData, eventData, columnNames, this.currentZone);

          blob = new Blob([blob, '\ufeff' + csvData], {
            type: 'text/csv;charset=utf-8;',
          });
          if (value.index === value.totalPages - 1) {
            this.downloadData(blob);
          }
        }),
        catchError(() => {
          this.messageService.add({ severity: SlbSeverity.Error, summary: CameraConstants.CSV_DOWNLOAD_ERROR_MSG });

          return of<EventDataIteration>({} as EventDataIteration);
        }),
        takeUntil(this.destroyed)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.cameraProfileService.setEventTime('');
    this.destroyed.next(true);
    this.destroyed.complete();
  }

  private downloadFiles(eventId: number[]): void {
    this.cameraProfileService
      .downloadMediaAsZip(this.gatewayId, this.controllerId, eventId)
      .pipe(
        tap((data: string) => {
          if (data && data !== '') {
            this.downloadZipFile(data);
          } else {
            this.messageService.add({ severity: SlbSeverity.Error, summary: FILE_DOWNLOAD_ERROR });
          }
        }),
        catchError(err => {
          console.error(err);
          throw err;
        }),
        takeUntil(this.destroyed)
      )
      .subscribe();
  }

  private generateDate(): void {
    switch (this.dayMode) {
      case 'hour':
        this.getDate(this.HOUR);
        break;
      case 'day':
        this.getDate(this.DAY);
        break;
      case 'week':
        this.getDate(this.WEEK);
        break;
    }
    if (this.event !== '') {
      this.getEvent();
    } else {
      this.isLoading = false;
    }
  }

  private getDate(hour: number): void {
    if (hour !== 0) {
      this.endDate = new Date();
      this.startDate = new Date(new Date().getTime() - hour * this.SECONDS_IN_HOUR);
    }
  }

  private getEvent(): void {
    let startTime: string;
    let endTime: string;
    this.isLoading = true;
    this.cameraProfileService.eventData$
      .pipe(
        tap(_value => {
          startTime = this.calculateTimeBasedOnTimezone(this.startDate);
          endTime = this.calculateTimeBasedOnTimezone(this.endDate);
          this.selectedFiles = [];
        }),
        switchMap(() =>
          this.cameraProfileService
            .getCameraEventData(
              this.gatewayId,
              this.controllerId,
              startTime,
              endTime,
              this.pageNumber,
              this.recordsPerPage,
              this.reviewCategory.toLowerCase(),
              this.event
            )
            .pipe(
              tap((eventData: EventData) => {
                if (eventData?.data) {
                  if (this.event !== '') {
                    this.violationDataList = eventData?.data;
                    this.totalRecords = eventData?.totalRecords;
                    this.checkAll = false;
                    this.violationDataList?.forEach(data => {
                      data.cameraStatus = this.cameraStatus;
                      data.cameraName = this.cameraName;
                      if (data?.rigState) {
                        data.rigState = this.dashboardService.getRigEventDetails(data);
                      } else {
                        data.rigState = CameraConstants.NoRigData;
                      }
                      if (data?.isChecked) {
                        data.isChecked = false;
                      }

                      return data;
                    });
                  }
                }
                this.isLoading = false;
              }),
              catchError(() => {
                this.messageService.add({ severity: SlbSeverity.Error, summary: EVENTERROR });
                this.isLoading = false;

                return of<string>('');
              }),
              takeUntil(this.destroyed)
            )
        )
      )
      .subscribe();
  }

  private updateSelection(): void {
    this.violationDataList.forEach((data: EventDetails) => {
      data.isChecked = false;
    });
    this.selectedFiles.forEach((file: EventDetails) => {
      this.violationDataList.forEach((data: EventDetails) => {
        if (file.eventId === data.eventId) {
          data.isChecked = true;
        }
      });
    });
  }

  private downloadZipFile(url: string): void {
    const blob = new Blob([url], {
      type: 'application/zip',
    });
    const bloburl = window.URL.createObjectURL(blob);
    const filename = 'SLB Edge-Vision Intelligence-' + this.controllerId;
    const dwldLink = document.createElement('a');

    dwldLink.setAttribute('href', bloburl);
    dwldLink.setAttribute('download', filename + '.zip');
    dwldLink.setAttribute('target', '_blank');
    dwldLink.click();
    dwldLink.remove();
  }

  private calculateTimeBasedOnTimezone(date: Date): string {
    if (date) {
      const dateBdOnChosedTimezone = new DatePipe('en-Us').transform(date, 'EEEE, MMMM d, y, h:mm:ss a zzzz', this.currentZone);
      if (dateBdOnChosedTimezone) {
        const basedonCurrentTimezone: Date = new Date(dateBdOnChosedTimezone);

        return basedonCurrentTimezone.toISOString();
      }

      return '';
    }

    return '';
  }

  private checkTimeRange(): void {
    const eventTimeinms = this.eventTime.getTime();
    if (eventTimeinms > new Date().getTime() - this.SECONDS_IN_HOUR) {
      this.dayMode = 'hour';
      this.getDate(this.HOUR);
    } else if (eventTimeinms > new Date().getTime() - 24 * this.SECONDS_IN_HOUR) {
      this.dayMode = 'day';
      this.getDate(this.DAY);
    } else if (eventTimeinms > new Date().getTime() - 168 * this.SECONDS_IN_HOUR) {
      this.dayMode = 'week';
      this.getDate(this.WEEK);
    } else {
      this.dayMode = 'custom';
      this.endDate = new Date(eventTimeinms);
      this.startDate = new Date(this.endDate.getTime() - 24 * this.SECONDS_IN_HOUR);
    }
    this.getEvent();
  }

  private downloadData(blob: Blob): void {
    const dwldLink = document.createElement('a');
    const url = URL.createObjectURL(blob);
    const isSafariBrowser = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
    const filename = `${this.cameraName.replace(' ', '')}-EventDetails-${new Date().toLocaleString()}`;

    if (isSafariBrowser) {
      dwldLink.setAttribute('target', '_blank');
    }
    dwldLink.setAttribute('href', url);
    dwldLink.setAttribute('download', filename + '.csv');
    dwldLink.style.visibility = 'hidden';
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
    this.isDownloadStarted = false;
    this.messageService.clear();
  }
}
