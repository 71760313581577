import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil, tap } from 'rxjs';
import { CameraDetails } from 'src/app/shared/models/cameraDetails';
import { CameraProfileService } from 'src/app/shared/services/camera-profile.service';

@Component({
  selector: 'app-camera-profile-header',
  templateUrl: './camera-profile-header.component.html',
  styleUrls: ['./camera-profile-header.component.scss'],
})
export class CameraProfileHeaderComponent implements OnInit, OnDestroy {
  public profileData: CameraDetails;
  private destroyed = new Subject();

  constructor(private cameraProfileService: CameraProfileService) {}

  ngOnInit(): void {
    this.cameraProfileService.profileData$
      .pipe(
        tap(profileDetails => {
          this.profileData = profileDetails;
        }),
        takeUntil(this.destroyed)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroyed.next(true);
  }
}
