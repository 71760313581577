import { Component, OnInit, ViewChild, AfterViewInit, Input, OnDestroy } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { SelectionModel } from '@angular/cdk/collections';
import { Router } from '@angular/router';
import { MatTableDataSource, MatTableDataSourcePaginator } from '@angular/material/table';

import { MessageService, SlbSeverity } from '@slb-dls/angular-material/notification';
import { tap, takeUntil, catchError, of, lastValueFrom } from 'rxjs';
import { Subject } from 'rxjs';

import { CameraList, Gateway, RigDetails, GatewayCameraList } from '../../app/shared/models/camera';
import { CameraService } from '../shared/services/camera.service';
import { CameraConstants } from '../shared/constants/camera-constant';
import { MatSelect } from '@angular/material/select';
import { DatePipe } from '@angular/common';
import { TimeZoneService } from '../shared/services/time-zone.service';

@Component({
  selector: 'app-camera',
  templateUrl: './camera.component.html',
  styleUrls: ['./camera.component.scss'],
})
export class CameraComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('gatewaySelect', { static: true }) gatewaySelect: MatSelect;
  @ViewChild('cameraSelect', { static: true }) cameraSelect: MatSelect;
  @Input() id = CameraConstants.Id;

  public cameraSelectionList: GatewayCameraList[] = [];
  public camera = CameraConstants.All;
  public selectedGateway = CameraConstants.All;
  public displayedColumns = CameraConstants.DisplayedColumns;
  public columnsForDownload = CameraConstants.ColumnsForDownload;
  public rigData: RigDetails;
  public cameraList: CameraList[] = [];
  public selection = new SelectionModel<CameraList>(true, []);
  public dataSource: MatTableDataSource<CameraList, MatTableDataSourcePaginator>;
  public disableDownload: boolean;
  public filteredGatewayName: string;
  public filteredCameraName: string;
  public gatewayList: Gateway;
  public initialGatewayList: Gateway;
  public selectedGatewayData: string | null;
  public totalRows: number;
  public isLoading: boolean;
  public pageNumber = CameraConstants.PAGE_NUMBER;
  public recordsPerPage = CameraConstants.RECORDS_PER_PAGE;
  public currentZone = '';
  private destroySubject = new Subject();
  private initialCameraSelectionList: GatewayCameraList[] = [];
  private country = 'all';

  constructor(
    public router: Router,
    private timeZoneService: TimeZoneService,
    private cameraService: CameraService,
    private messageService: MessageService,
    private datePipe: DatePipe
  ) {
    this.dataSource = new MatTableDataSource<CameraList>([]);
    this.currentZone = this.timeZoneService.getTimeZone();
    const country = sessionStorage.getItem('country');
    if (country) {
      this.country = country === '' ? 'all' : country;
    }
  }

  ngOnInit(): void {
    this.disableDownload = true;
    this.getCameraListDetails(this.selectedGateway, this.camera, this.pageNumber, this.recordsPerPage, this.country);
    this.cameraService
      .getGatewayHierarchy(this.country)
      .pipe(
        tap((gatewayList: Gateway) => {
          if (gatewayList) {
            this.gatewayList = gatewayList;
            this.initialGatewayList = JSON.parse(JSON.stringify(this.gatewayList));
            this.gatewayList.gateways.forEach(gateway => {
              if (gateway.cameras) {
                this.cameraSelectionList = [...this.cameraSelectionList, ...gateway.cameras];
                this.initialCameraSelectionList = [...this.initialCameraSelectionList, ...gateway.cameras];
              }
            });
          }
        }),
        catchError(() => {
          this.messageService.add({ severity: SlbSeverity.Error, summary: CameraConstants.GATEWAY_HIERARCHY_ERROR_MSG });

          return of<RigDetails>({} as RigDetails);
        }),
        takeUntil(this.destroySubject)
      )
      .subscribe();

    this.gatewaySelect._handleKeydown = (event: KeyboardEvent): void => {
      this.uncloseSpaceBtn(this.gatewaySelect, event);
    };

    this.cameraSelect._handleKeydown = (event: KeyboardEvent): void => {
      this.uncloseSpaceBtn(this.gatewaySelect, event);
    };
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  ngOnDestroy(): void {
    this.destroySubject.next(true);
  }

  public onPageFired(event: PageEvent): void {
    this.selection.clear();
    this.pageNumber = event.pageIndex + 1;
    this.recordsPerPage = event.pageSize;
    this.selectedGatewayData = this.selectedGatewayData ? this.selectedGatewayData : CameraConstants.All;
    this.getCameraListDetails(this.selectedGatewayData, this.camera, event.pageIndex + 1, event.pageSize, this.country);
  }

  public filterGatewayList(event: Event): void {
    this.filteredGatewayName = (event.target as HTMLInputElement).value ?? '';
    if (this.filteredGatewayName === '' || this.filteredGatewayName.toLowerCase() === 'all') {
      this.gatewayList = JSON.parse(JSON.stringify(this.initialGatewayList));
    } else {
      this.gatewayList = JSON.parse(JSON.stringify(this.initialGatewayList));
      this.gatewayList.gateways = this.gatewayList?.gateways.filter(el =>
        el?.gatewayName?.toLowerCase().includes(this.filteredGatewayName?.toLowerCase())
      );
    }
  }

  public filterCameraList(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value ?? '';
    if (filterValue !== '' || filterValue.trim().toLowerCase() !== 'allcamera') {
      this.cameraSelectionList = this.initialCameraSelectionList.filter(el => el?.name?.toLowerCase().includes(filterValue.toLowerCase()));
    }
  }

  public isSelectBoxOpened(openFlag: boolean): void {
    if (!openFlag) {
      this.filteredGatewayName = '';
      this.filteredCameraName = '';
      this.gatewayList = this.initialGatewayList;
      this.cameraSelectionList =
        this.initialGatewayList.gateways.filter(el => el.gatewayId === this.selectedGatewayData)[0]?.cameras ??
        this.initialCameraSelectionList;
    }
  }

  public changeGateway(gatewayId: string): void {
    this.selection.clear();
    this.disableDownload = true;
    this.camera = CameraConstants.All;
    this.getCameraListDetails(gatewayId, this.camera, this.pageNumber, this.recordsPerPage, this.country);
    this.selectedGatewayData = gatewayId;
    this.cameraSelectionList = [];
    if (gatewayId === CameraConstants.All) {
      this.initialGatewayList.gateways.forEach(gateway => {
        if (gateway?.cameras) {
          this.cameraSelectionList.push(...gateway.cameras);
        }
      });
    } else {
      this.cameraSelectionList =
        this.initialGatewayList.gateways.filter(el => el.gatewayId === gatewayId)[0]?.cameras ?? this.initialCameraSelectionList;
    }
    this.initialCameraSelectionList = JSON.parse(JSON.stringify(this.cameraSelectionList));
  }

  public changeCamera(camera: string): void {
    this.selection.clear();
    this.disableDownload = true;
    this.getCameraListDetails(this.selectedGatewayData ?? CameraConstants.All, camera, this.pageNumber, this.recordsPerPage, this.country);
  }

  public isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.cameraList.length;

    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  public masterToggle(): void {
    if (this.isAllSelected()) {
      this.selection.clear();
      this.disableDownload = true;
    } else {
      this.cameraList.forEach(row => this.selection.select(row));
      this.disableDownload = false;
    }
  }

  public rowSelection(): void {
    if (this.selection.selected.length > 0) {
      this.disableDownload = false;
    } else {
      this.disableDownload = true;
    }
  }

  public downloadCameraDetails(): void {
    const cameraList: CameraList[] = JSON.parse(JSON.stringify(this.convertEnumToString(this.selection.selected)));
    if (cameraList?.length > 0) {
      cameraList.forEach(camera => {
        if (camera?.lastUpdatedStatus && camera.lastUpdatedStatus !== '') {
          const dateconversion = this.datePipe.transform(new Date(camera.lastUpdatedStatus), 'MM/dd/yyyy HH:mm:ss a', this.currentZone);
          if (dateconversion) {
            camera.lastUpdatedStatus = dateconversion;
            camera.site = camera.rigName;
          }
        } else {
          camera.lastUpdatedStatus = '';
          camera.site = camera.rigName;
        }
      });
    }
    const csvData = this.convertToCsv(cameraList, this.columnsForDownload);
    const blob = new Blob(['\ufeff' + csvData], {
      type: 'text/csv;charset=utf-8;',
    });
    const dwldLink = document.createElement('a');
    const url = URL.createObjectURL(blob);
    const filename = 'CameraList - ' + new Date();

    dwldLink.setAttribute('href', url);
    dwldLink.setAttribute('download', filename + '.csv');
    dwldLink.style.visibility = 'hidden';
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  public convertToCsv(objArray: CameraList[], headerList: string[]): string {
    const array = typeof objArray !== 'object' ? JSON.parse(objArray as string) : objArray;
    let str = '';
    let row = '';
    for (const index in headerList) {
      if (headerList.hasOwnProperty(index)) {
        if (headerList[index].toLowerCase() === 'rigname') {
          row += 'Site,';
        } else {
          row += headerList[index].charAt(0).toUpperCase() + headerList[index].slice(1) + ',';
        }
      }
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (const index in headerList) {
        if (headerList.hasOwnProperty(index)) {
          const head = headerList[index];
          line += (array[i][head] ?? '') + ',';
        }
      }
      line = line.replace(/,\s*$/, '');
      str += line + '\r\n';
    }

    return str;
  }

  public getRecord(row: CameraList): void {
    let rigName = row.rigName;
    if (row.rigName.includes(' ')) {
      rigName = row.rigName.split(' ').join('*');
    }
    sessionStorage.setItem('gatewayStatus', row.gatewayStatus.toString());
    this.router.navigate(['home', row.cameraName, row.cameraStatus, row.gatewayId, row.controllerId, rigName]);
  }

  private convertEnumToString(objArray: CameraList[]): CameraList[] {
    objArray.forEach(el => {
      if (el.realTimeRigDataStatus === 1) {
        el.realTimeRigStatus = CameraConstants.ACTIVE;
      } else {
        el.realTimeRigStatus = CameraConstants.DISCONNECTED;
      }
    });

    return objArray;
  }

  private async getCameraListDetails(
    gatewayId: string | null,
    controllerId: string | null,
    pageNumber: number,
    recordPerPage: number,
    country: string
  ): Promise<void> {
    this.isLoading = true;
    this.disableDownload = true;
    await lastValueFrom(this.cameraService.getCameraList(gatewayId, controllerId, pageNumber, recordPerPage, country))
      .then(response => {
        this.rigData = response as RigDetails;
        this.rigData.data.cameraList.forEach(camera => {
          if (camera.gatewayStatus === 2) {
            camera.cameraStatus = 2;
            camera.realTimeRigDataStatus = 0;
          }
        });
      })
      .catch(() => {
        this.messageService.add({ severity: SlbSeverity.Error, summary: CameraConstants.CAMERA_LIST_ERROR_MSG });
        this.isLoading = false;
      });
    this.rigData?.data?.cameraList.sort((a: CameraList, b: CameraList) => a.gatewayName.localeCompare(b.gatewayName));
    this.cameraList = this.rigData.data.cameraList;
    this.totalRows = this.rigData.totalRecords;

    this.dataSource = new MatTableDataSource(this.rigData.data.cameraList);
    this.isLoading = false;
  }

  private uncloseSpaceBtn(selectData: any, event: KeyboardEvent): void {
    if (event.key === ' ') {
      return;
    }
    if (!selectData.disabled) {
      if (selectData.panelOpen) {
        selectData._handleOpenKeydown(event);
      } else {
        selectData._handleClosedKeydown(event);
      }
    }
  }
}
