<div class="zone-configuration-title">
  <span mat-dialog-title class="zone-dialog-title">Zone definition</span>
  <slb-modal-close-button
    buttonType="Icon"
    class="align-position"
    [dialogRef]="dialogRef"
    id="zone_configuration_dialog_close_icon"></slb-modal-close-button>
</div>
<div mat-dialog-content class="zone-dialog-content">
  <div class="image-video-selection">
    <button
      mat-button
      slb-button
      (click)="getImages()"
      [disabled]="isLoading || inactiveGateway || inactiveCamera"
      class="zone-btn"
      id="zone_configuration_request_image"
      cdkFocus>
      Request an image
    </button>
    <mat-spinner *ngIf="isLoading" [diameter]="20"></mat-spinner>
    <span *ngIf="checkError()" class="error-msg">
      {{ errorMessage }}
    </span>
  </div>
  <div class="image-video-preview">
    <canvas #canvas [width]="deviceWidth" [height]="deviceHeight"></canvas>
  </div>
</div>
<mat-dialog-actions *ngIf="mediaUrl" class="dialog-footer">
  <div class="zone-dialog-actions" *ngIf="mediaUrl">
    <mat-form-field class="zone-name-field" slbFormField>
      <mat-label> Zone name </mat-label>
      <input
        matInput
        pattern="^[a-zA-Z0-9\-\s]*$"
        required
        name="zoneNameField"
        #zoneNameField="ngModel"
        [(ngModel)]="zoneName"
        maxlength="25"
        [ngModelOptions]="{ standalone: true }"
        [disabled]="!isZoneAdded"
        (focusout)="redrawOnCanvas()"
        (ngModelChange)="checkZoneNameValid()" />
      <mat-hint
        *ngIf="
          (!zoneName || (zoneName && !zoneName.trim().length) || zoneNameField.errors || !isZoneNameValid) &&
          isZoneAdded &&
          (zoneNameField.dirty || zoneNameField.touched)
        ">
        <span class="error-msg"> Please enter a valid zone name. </span>
      </mat-hint>
    </mat-form-field>
    <button mat-button slb-button [disabled]="!isZoneAdded" (click)="removeRedzone()">
      <mat-icon svgIcon="delete" class="delete-icon"></mat-icon>
      Remove current red zone
    </button>
  </div>
  <div class="zone-dialog-actions">
    <mat-spinner *ngIf="isSaveLoading" [diameter]="20"></mat-spinner>
    <button id="zone_configuration_dialog_close" mat-button slb-secondary-button type="button" (click)="closeZoneConfiguration()">
      Close
    </button>
    <button
      id="zone_configuration_dialog_submit"
      mat-button
      slb-button
      type="submit"
      cdkFocusInitial
      (click)="saveZoneConfiguration()"
      [disabled]="!isZonechange || !zoneName.trim().length || !isZoneNameValid || isSaveLoading || !isZoneAdded">
      Save
    </button>
  </div>
</mat-dialog-actions>
